import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

//////import {
//////    ano,
//////    mesMesAno,
//////    anoMesAno,
//////} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosSubTotal0Titulo,
    formataTableCellBodyExibeDadosSubTotal0Valor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import mesesEmergencialList from '../../../business-rules/List/mesesEmergencialList'

import tipoPatrimonioImobilizadoList from '../../../business-rules/List/tipoPatrimonioImobilizadoList'

import titularList from '../../../business-rules/List/titularList'

import ufList from '../../../business-rules/List/ufList'

import tipoRecursoFinanceiroList from '../../../business-rules/List/tipoRecursoFinanceiroList'

import {
    dadosCompletosConjuge,
    calculaValorPerc,
//////    calculaValorMensal,
} from '../cliente-form/ClienteService'

import {
    calculaDiaDiaSemDespesasTemporarias,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    dadosCompletosLinhaPatrimonioImobilizado,
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'

import {
    dadosCompletosLinhaRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

//////import {
//////    calculaTaxaRetornoLiquida
//////} from './EstudoServiceMatematicaFinanceira'

//////import {
//////    temAporteDividendos,
//////    calculaValorAporteDividendos,
//////} from './EstudoServiceGarantiasRecursosFinanceiros'

//////import {
//////    calculaIndexGraficoAnosIdades,
//////    calculaGraficoAcumulacaoMensal,
//////} from './EstudoServiceCalculo'

export const processaValoresDefaultIniciaisSucessaoVitalicia = ( values ) => {

    // O valor default dos campos percDespesasAdvocaticias, percCustasJudiciais, mesesEmergencialCliente e
    // mesesEmergencialConjuge foram implementados em ClienteService - inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient
    if (!values.alterarValoresAutomaticosSucessaoVitaliciaCliente) {
        values.alterarValoresAutomaticosSucessaoVitaliciaCliente = false
    }

    if (!values.alterarValoresAutomaticosSucessaoVitaliciaConjuge) {
        values.alterarValoresAutomaticosSucessaoVitaliciaConjuge = false
    }

    if (!values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia) {

        values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia = false
    }

    if (!values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia) {

        values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia = false
    }
}

const calculaSucessaoVitaliciaEmergencial = ( values, mesesEmergencial ) => {

    const meses = pesquisaList(mesesEmergencialList, mesesEmergencial).meses

    const diaDiaSemDespesasTemporarias = calculaDiaDiaSemDespesasTemporarias(values)

    return round(meses * diaDiaSemDespesasTemporarias, 2)
}

export const processaAlteracaoSucessaoVitaliciaMesesEmergencialCliente = ( values ) => {
    if (!values.alterarValoresAutomaticosSucessaoVitaliciaCliente) {
        values.emergencialCliente = calculaSucessaoVitaliciaEmergencial(values, values.mesesEmergencialCliente)
    }
}

export const processaAlteracaoSucessaoVitaliciaMesesEmergencialConjuge = ( values ) => {
    if (!values.alterarValoresAutomaticosSucessaoVitaliciaConjuge) {
        values.emergencialConjuge = calculaSucessaoVitaliciaEmergencial(values, values.mesesEmergencialConjuge)
    }
}



const aumentoLetraSucessaoVitalicia = (values) => {
        
    const aumentoLetra = 4
    const aumentoLetraReport = values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? 3 : 5

    return { aumentoLetra, aumentoLetraReport }
}



export const calculaSucessaoVitaliciaDefaultPatrimonioImobilizado = ( values, estudo, report, sucessaoVitaliciaTotalGeral ) => {
    let patrimonioImobilizadoTemp = []
    let patrimonioImobilizado = []

    if (values.patrimonioImobilizado) {
        let descricaoTipoPatrimonioImobilizado = null
        let nomeCurtoFamiliar = null
        
        let valor = 0
        let valorCliente = 0
        let valorConjuge = 0
        let percDespesasAdvocaticias = 0
        let percCustasJudiciais = 0
        let percItcmd = 0
        let valorInventario = 0
        let valorInventarioDespesasAdvocaticias = 0
        let valorInventarioCustasJudiciais = 0
        let valorInventarioItcmd = 0
        let valorInventarioCliente = 0
        let valorInventarioDespesasAdvocaticiasCliente = 0
        let valorInventarioCustasJudiciaisCliente = 0
        let valorInventarioItcmdCliente = 0
        let valorInventarioConjuge = 0
        let valorInventarioDespesasAdvocaticiasConjuge = 0
        let valorInventarioCustasJudiciaisConjuge = 0
        let valorInventarioItcmdConjuge = 0

        let valorTotal = 0
        let valorClienteTotal = 0
        let valorConjugeTotal = 0
        let valorInventarioTotal = 0
        let valorInventarioDespesasAdvocaticiasTotal = 0
        let valorInventarioCustasJudiciaisTotal = 0
        let valorInventarioItcmdTotal = 0
        let valorInventarioClienteTotal = 0
        let valorInventarioDespesasAdvocaticiasClienteTotal = 0
        let valorInventarioCustasJudiciaisClienteTotal = 0
        let valorInventarioItcmdClienteTotal = 0
        let valorInventarioConjugeTotal = 0
        let valorInventarioDespesasAdvocaticiasConjugeTotal = 0
        let valorInventarioCustasJudiciaisConjugeTotal = 0
        let valorInventarioItcmdConjugeTotal = 0

        values.patrimonioImobilizado.forEach( (linhaPatrimonioImobilizado, index) => {

            if (
                    dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaPatrimonioImobilizado.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.patrimonioImobilizado + '/' + linhaPatrimonioImobilizado.id).id &&
                    pesquisaList(tipoPatrimonioImobilizadoList, linhaPatrimonioImobilizado.tipoPatrimonioImobilizado).informaUfItcmd === "S" &&
                    !linhaPatrimonioImobilizado.naoInventariar
            ) {

                descricaoTipoPatrimonioImobilizado = 
                    linhaPatrimonioImobilizado.descricaoOutros
                        ?
                            linhaPatrimonioImobilizado.descricaoOutros
                        :
                            pesquisaDescricaoList(tipoPatrimonioImobilizadoList, 
                                linhaPatrimonioImobilizado.tipoPatrimonioImobilizado)

                nomeCurtoFamiliar = 
                    pesquisaDescricaoList(titularList(values, 'completo'), 
                        linhaPatrimonioImobilizado.familiar)

                valor = round (linhaPatrimonioImobilizado.valor, 2)

                percDespesasAdvocaticias = values.percDespesasAdvocaticias
                percCustasJudiciais = values.percCustasJudiciais

                if (values.percInventarioItcmd) {

                    percItcmd = values.percInventarioItcmd

                } else {
                    
                    if (linhaPatrimonioImobilizado.ufItcmd) {

                        percItcmd = pesquisaList(ufList, linhaPatrimonioImobilizado.ufItcmd).percInventarioItcmd

                    } else {

                        percItcmd = HARDCODE.percInventarioItcmdMaximo

                    }
                }

                valorInventario =
                    round(valor * ((percDespesasAdvocaticias + percCustasJudiciais + percItcmd) / 100), 2)

                valorInventarioDespesasAdvocaticias = round(valor * ((percDespesasAdvocaticias) / 100), 2)
                valorInventarioCustasJudiciais = round(valor * ((percCustasJudiciais) / 100), 2)
                valorInventarioItcmd = valorInventario - valorInventarioDespesasAdvocaticias - valorInventarioCustasJudiciais

                valorCliente = 0
                valorInventarioCliente = 0
                valorInventarioDespesasAdvocaticiasCliente = 0
                valorInventarioCustasJudiciaisCliente = 0
                valorInventarioItcmdCliente = 0

                valorConjuge = 0
                valorInventarioConjuge = 0
                valorInventarioDespesasAdvocaticiasConjuge = 0
                valorInventarioCustasJudiciaisConjuge = 0
                valorInventarioItcmdConjuge = 0

                if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCliente) {
                    valorCliente = valor

                    valorInventarioCliente = valorInventario

                    valorInventarioDespesasAdvocaticiasCliente = valorInventarioDespesasAdvocaticias
                    valorInventarioCustasJudiciaisCliente = valorInventarioCustasJudiciais
                    valorInventarioItcmdCliente = valorInventarioItcmd
                }

                if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarConjuge) {
                    valorConjuge = valor

                    valorInventarioConjuge = valorInventario

                    valorInventarioDespesasAdvocaticiasConjuge = valorInventarioDespesasAdvocaticias
                    valorInventarioCustasJudiciaisConjuge = valorInventarioCustasJudiciais
                    valorInventarioItcmdConjuge = valorInventarioItcmd
                }

                if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal) {
                    valorCliente = calculaValorPerc(valor, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                    valorConjuge = valor - valorCliente

                    valorInventarioCliente = calculaValorPerc(valorInventario, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                    valorInventarioConjuge = valorInventario - valorInventarioCliente

                    valorInventarioDespesasAdvocaticiasCliente =
                        calculaValorPerc(valorInventarioDespesasAdvocaticias, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                    valorInventarioCustasJudiciaisCliente =
                        calculaValorPerc(valorInventarioCustasJudiciais, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                    valorInventarioItcmdCliente =
                        calculaValorPerc(valorInventarioItcmd, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)

                    valorInventarioDespesasAdvocaticiasConjuge = valorInventarioDespesasAdvocaticias - valorInventarioDespesasAdvocaticiasCliente
                    valorInventarioCustasJudiciaisConjuge = valorInventarioCustasJudiciais - valorInventarioCustasJudiciaisCliente
                    valorInventarioItcmdConjuge = valorInventarioItcmd - valorInventarioItcmdCliente
                }

                patrimonioImobilizadoTemp.push({
                    ordem: ("0000" + (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal ? 0 : linhaPatrimonioImobilizado.familiar)).slice(-4) + 
                        ("000000000000000000000" + valor).slice(-21),
                    familiar: linhaPatrimonioImobilizado.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricaoTipoPatrimonioImobilizado: descricaoTipoPatrimonioImobilizado,
                    valor: valor,
                    percDespesasAdvocaticias: percDespesasAdvocaticias,
                    percCustasJudiciais: percCustasJudiciais,
                    percDespesasAdvocaticiasCustasJudiciais: percDespesasAdvocaticias + percCustasJudiciais,
                    percItcmd: percItcmd,
                    valorInventario: valorInventario,
                    valorInventarioCliente: valorInventarioCliente,
                    valorInventarioConjuge: valorInventarioConjuge,
                })

                valorTotal += valor

                valorClienteTotal += valorCliente
                valorConjugeTotal += valorConjuge

                valorInventarioTotal += valorInventario
                valorInventarioDespesasAdvocaticiasTotal += valorInventarioDespesasAdvocaticias
                valorInventarioCustasJudiciaisTotal += valorInventarioCustasJudiciais
                valorInventarioItcmdTotal += valorInventarioItcmd

                valorInventarioClienteTotal += valorInventarioCliente
                valorInventarioDespesasAdvocaticiasClienteTotal += valorInventarioDespesasAdvocaticiasCliente
                valorInventarioCustasJudiciaisClienteTotal += valorInventarioCustasJudiciaisCliente
                valorInventarioItcmdClienteTotal += valorInventarioItcmdCliente

                valorInventarioConjugeTotal += valorInventarioConjuge
                valorInventarioDespesasAdvocaticiasConjugeTotal += valorInventarioDespesasAdvocaticiasConjuge
                valorInventarioCustasJudiciaisConjugeTotal += valorInventarioCustasJudiciaisConjuge
                valorInventarioItcmdConjugeTotal += valorInventarioItcmdConjuge
            }
        })

        patrimonioImobilizadoTemp = sortAsc(patrimonioImobilizadoTemp)

        let familiarAnt = null

        const { aumentoLetra, aumentoLetraReport } = aumentoLetraSucessaoVitalicia(values) 

        patrimonioImobilizadoTemp.forEach( (linhaPatrimonioImobilizado, index) => {
            if (linhaPatrimonioImobilizado.familiar !== familiarAnt) {
                familiarAnt = linhaPatrimonioImobilizado.familiar

                valor = 0
                valorInventario = 0
                valorInventarioCliente = 0
                valorInventarioConjuge = 0

                patrimonioImobilizadoTemp.forEach( (linhaPatrimonioImobilizadoTotal, index) => {
                    if (linhaPatrimonioImobilizadoTotal.familiar === linhaPatrimonioImobilizado.familiar) {
                        valor += linhaPatrimonioImobilizadoTotal.valor
                        valorInventario +=linhaPatrimonioImobilizadoTotal.valorInventario
                        valorInventarioCliente += linhaPatrimonioImobilizadoTotal.valorInventarioCliente
                        valorInventarioConjuge +=linhaPatrimonioImobilizadoTotal.valorInventarioConjuge
                    }
                })

                patrimonioImobilizado.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaPatrimonioImobilizado.nomeCurtoFamiliar, report,
                            false, aumentoLetra, aumentoLetraReport, values),
                    valor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventario: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventario, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventarioCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventarioCliente, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventarioConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventarioConjuge, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                })
            }

            patrimonioImobilizado.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("       " + 
                        linhaPatrimonioImobilizado.descricaoTipoPatrimonioImobilizado, report, false, false,
                            aumentoLetra, aumentoLetraReport, values),
                valor: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valor, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                percDespesasAdvocaticias:
                    formataTableCellBodyExibeDadosTexto(linhaPatrimonioImobilizado.percDespesasAdvocaticias, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                percCustasJudiciais:
                    formataTableCellBodyExibeDadosTexto(linhaPatrimonioImobilizado.percCustasJudiciais, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                percDespesasAdvocaticiasCustasJudiciais:
                    formataTableCellBodyExibeDadosTexto(linhaPatrimonioImobilizado.percDespesasAdvocaticiasCustasJudiciais,
                        report, false, aumentoLetra, aumentoLetraReport, values),
                percItcmd:
                    formataTableCellBodyExibeDadosTexto(linhaPatrimonioImobilizado.percItcmd, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                valorInventario: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorInventario, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                valorInventarioCliente: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorInventarioCliente, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                valorInventarioConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorInventarioConjuge, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
            })
        })

        patrimonioImobilizado.push({
            descricao:
                formataTableCellBodyExibeDadosSubTotal0Titulo(LABEL.patrimonioImobilizado.toUpperCase() /* mudança da fonte: uppercase */,
                    report, false, aumentoLetra, aumentoLetraReport, values),
            valor:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorTotal, report, false,
                    true, aumentoLetra, aumentoLetraReport, values),
            valorInventario:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
            valorInventarioCliente:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioClienteTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
            valorInventarioConjuge:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioConjugeTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
        })

        //patrimonioImobilizado.push({
        //    descricao:
        //        formataTableCellBodyExibeDadosSubTotalTitulo(" ", report, false, aumentoLetra, aumentoLetraReport, values)
        //})

        sucessaoVitaliciaTotalGeral.valor += valorTotal

        sucessaoVitaliciaTotalGeral.valorPatrimonioImobilizado += valorTotal
        sucessaoVitaliciaTotalGeral.valorClientePatrimonioImobilizado += valorClienteTotal
        sucessaoVitaliciaTotalGeral.valorConjugePatrimonioImobilizado += valorConjugeTotal

        sucessaoVitaliciaTotalGeral.valorInventario += valorInventarioTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticias += valorInventarioDespesasAdvocaticiasTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciais += valorInventarioCustasJudiciaisTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmd += valorInventarioItcmdTotal

        sucessaoVitaliciaTotalGeral.valorInventarioCliente += valorInventarioClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticiasCliente += valorInventarioDespesasAdvocaticiasClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciaisCliente += valorInventarioCustasJudiciaisClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmdCliente += valorInventarioItcmdClienteTotal

        sucessaoVitaliciaTotalGeral.valorInventarioConjuge += valorInventarioConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticiasConjuge += valorInventarioDespesasAdvocaticiasConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciaisConjuge += valorInventarioCustasJudiciaisConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmdConjuge += valorInventarioItcmdConjugeTotal

        sucessaoVitaliciaTotalGeral.valorInventarioPatrimonioImobilizado +=valorInventarioTotal
    }

    estudo.current.inventario.push(...patrimonioImobilizado)
}

export const obtemUfItcmdEnderecoResidencial = ( values ) => {

    let ufItcmd = ''

    values.enderecos?.forEach( (linhaEnderecos, index) => {
        if (
                !ufItcmd &&
                linhaEnderecos.tipoEndereco === HARDCODE.idTipoEnderecoResidencial &&
                linhaEnderecos.uf
        ) {
            ufItcmd = linhaEnderecos.uf
        }
    })

    return ufItcmd
}

export const calculaItcmdEnderecoResidencial = ( values ) => {

    let itcmd

    if (values.percInventarioItcmd) {

        itcmd = values.percInventarioItcmd

    } else {

        const ufItcmd = obtemUfItcmdEnderecoResidencial(values)

        if (ufItcmd) {

            itcmd = pesquisaList(ufList, ufItcmd).percInventarioItcmd

        } else {

            itcmd = HARDCODE.percInventarioItcmdMaximo
        }  
    }

    return itcmd
}

export const possuiRecursosFinanceirosInventario = ( values/*, estudo*/ ) => {
    let possui = false

    values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

        const linhaTipoRecursoFinanceiroList = 
            pesquisaList(tipoRecursoFinanceiroList,
                linhaRecursosFinanceiros.tipoRecursoFinanceiro)

        if (
                dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros) &&
                //(
                linhaTipoRecursoFinanceiroList.inventario === "S"/* ||
                    (
                        linhaTipoRecursoFinanceiroList.inventario === HARDCODE.inventarioPrevidenciaPrivada &&
                        estudo.current.jsonConfiguracaoEstudo.considerarPrevidenciaPrivadaInventario
                    )*/
                /*)*/ &&
                !linhaRecursosFinanceiros.naoInventariar
        ) {
            possui = true
        }
    })

    return possui
}

export const calculaSucessaoVitaliciaDefaultRecursosFinanceiros = ( values, estudo, report, sucessaoVitaliciaTotalGeral ) => {

    let recursosFinanceirosTemp = []
    let recursosFinanceiros = []

    if (values.recursosFinanceiros) {
        let linhaTipoRecursoFinanceiro
        let descricaoTipoRecursoFinanceiro = null

        let titularListAux = titularList(values, 'completo')
        let linhaTitularList
        let nomeCurtoFamiliar = null

        //////let anoFinal = values.anoFinalVitalicioClienteConjuge
        //////let mesInicialAporteDividendosContribuicao
        //////let anoInicialAporteDividendosContribuicao
        //////let mesFinalAporteDividendosContribuicao
        //////let anoFinalAporteDividendosContribuicao
        
        //////let taxaRetornoAnualReal
        //////let taxaRetornoAnualRealAposAposentadoria

        let valor = 0
        let valorCliente = 0
        let valorConjuge = 0
        let percDespesasAdvocaticias = 0
        let percCustasJudiciais = 0
        let percItcmd = 0
        let valorInventario = 0
        let valorInventarioDespesasAdvocaticias = 0
        let valorInventarioCustasJudiciais = 0
        let valorInventarioItcmd = 0
        let valorInventarioCliente = 0
        let valorInventarioDespesasAdvocaticiasCliente = 0
        let valorInventarioCustasJudiciaisCliente = 0
        let valorInventarioItcmdCliente = 0
        let valorInventarioConjuge = 0
        let valorInventarioDespesasAdvocaticiasConjuge = 0
        let valorInventarioCustasJudiciaisConjuge = 0
        let valorInventarioItcmdConjuge = 0
        //////let valorAporteDividendosContribuicao
        //////let valorAporteDividendosContribuicaoMensal
        //////let valorAporteDividendosMensalInventario

        let valorTotal = 0
        let valorTotalDespesasAdvocaticiasCustasJudiciais = 0
        let valorClienteTotal = 0
        let valorConjugeTotal = 0
        let valorInventarioTotal = 0
        let valorInventarioDespesasAdvocaticiasTotal = 0
        let valorInventarioCustasJudiciaisTotal = 0
        let valorInventarioItcmdTotal = 0
        let valorInventarioClienteTotal = 0
        let valorInventarioDespesasAdvocaticiasClienteTotal = 0
        let valorInventarioCustasJudiciaisClienteTotal = 0
        let valorInventarioItcmdClienteTotal = 0
        let valorInventarioConjugeTotal = 0
        let valorInventarioDespesasAdvocaticiasConjugeTotal = 0
        let valorInventarioCustasJudiciaisConjugeTotal = 0
        let valorInventarioItcmdConjugeTotal = 0

        //////let saldo

        values.recursosFinanceiros.forEach( (linhaRecursosFinanceiros, index) => {

            const linhaTipoRecursoFinanceiroList =
                pesquisaList(tipoRecursoFinanceiroList, linhaRecursosFinanceiros.tipoRecursoFinanceiro)

            if (
                    dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaRecursosFinanceiros.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.recursosFinanceiros + '/' + linhaRecursosFinanceiros.id).id &&
                    //(
                    linhaTipoRecursoFinanceiroList.inventario === "S"/* ||
                        (
                            linhaTipoRecursoFinanceiroList.inventario === HARDCODE.inventarioPrevidenciaPrivada &&
                            estudo.current.jsonConfiguracaoEstudo.considerarPrevidenciaPrivadaInventario
                        )
                    )*/ &&
                    !linhaRecursosFinanceiros.naoInventariar
            ) {
                
                linhaTipoRecursoFinanceiro =
                    pesquisaList(tipoRecursoFinanceiroList, linhaRecursosFinanceiros.tipoRecursoFinanceiro)

                descricaoTipoRecursoFinanceiro =
                    linhaRecursosFinanceiros.descricaoOutros
                        ?
                            linhaRecursosFinanceiros.descricaoOutros
                        :
                            linhaTipoRecursoFinanceiro.descricao

                linhaTitularList = 
                    pesquisaList(titularListAux, linhaRecursosFinanceiros.familiar)
                
                nomeCurtoFamiliar = linhaTitularList.descricao

                /*//////
                if (
                        values.utilizarPercRentabilidadeAnualRecursosFinanceiros &&
                        (
                            linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro ||
                            linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro === 0
                        )
                 ) {

                    taxaRetornoAnualReal =
                        calculaTaxaRetornoLiquida(
                            //round(//
                                linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro
                            //, 2)//
                                ,
                                linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro ||
                                linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro === 0
                                    ?
                                        linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro
                                    :
                                        values.taxaInflacaoAnual,
                                linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                                    ?
                                        linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                                    :
                                        values.tipoMomentoTributacaoIR,
                                linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                                    ?
                                        linhaRecursosFinanceiros.percIR
                                    :
                                        values.percIR,
                                linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                                    ?
                                        linhaRecursosFinanceiros.tempoMinimoEntreAportesResgatesCalculoIRAnos
                                    :
                                        values.tempoMinimoEntreAportesResgatesCalculoIRAnos
                        )
                    taxaRetornoAnualRealAposAposentadoria = taxaRetornoAnualReal

                } else {

                    taxaRetornoAnualReal = values.taxaRetornoAnualReal
                    taxaRetornoAnualRealAposAposentadoria = values.taxaRetornoAnualRealAposAposentadoria
                }
                */

                valor = round (linhaRecursosFinanceiros.valor, 2)

                percDespesasAdvocaticias = linhaTipoRecursoFinanceiro.somenteITCMD === 'S' ? 0 : values.percDespesasAdvocaticias
                percCustasJudiciais = linhaTipoRecursoFinanceiro.somenteITCMD === 'S' ? 0 : values.percCustasJudiciais
                percItcmd = calculaItcmdEnderecoResidencial(values)

                valorInventario =
                    round(valor * ((percDespesasAdvocaticias + percCustasJudiciais + percItcmd) / 100), 2)

                valorInventarioDespesasAdvocaticias = round(valor * ((percDespesasAdvocaticias) / 100), 2)
                valorInventarioCustasJudiciais = round(valor * ((percCustasJudiciais) / 100), 2)
                valorInventarioItcmd = valorInventario - valorInventarioDespesasAdvocaticias - valorInventarioCustasJudiciais

                valorCliente = 0
                valorInventarioCliente = 0
                valorInventarioDespesasAdvocaticiasCliente = 0
                valorInventarioCustasJudiciaisCliente = 0
                valorInventarioItcmdCliente = 0

                valorConjuge = 0
                valorInventarioConjuge = 0
                valorInventarioDespesasAdvocaticiasConjuge = 0
                valorInventarioCustasJudiciaisConjuge = 0
                valorInventarioItcmdConjuge = 0

                if (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente) {
                    valorCliente = valor

                    valorInventarioCliente = valorInventario
                    
                    valorInventarioDespesasAdvocaticiasCliente = valorInventarioDespesasAdvocaticias
                    valorInventarioCustasJudiciaisCliente = valorInventarioCustasJudiciais
                    valorInventarioItcmdCliente = valorInventarioItcmd
                }

                if (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarConjuge) {
                    valorConjuge = valor

                    valorInventarioConjuge = valorInventario

                    valorInventarioDespesasAdvocaticiasConjuge = valorInventarioDespesasAdvocaticias
                    valorInventarioCustasJudiciaisConjuge = valorInventarioCustasJudiciais
                    valorInventarioItcmdConjuge = valorInventarioItcmd
                }

                if (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal) {
                    valorCliente = calculaValorPerc(valor, linhaRecursosFinanceiros.percRecursosFinanceirosCliente)
                    valorConjuge = valor - valorCliente

                    valorInventarioCliente = calculaValorPerc(valorInventario, linhaRecursosFinanceiros.percRecursosFinanceirosCliente)
                    valorInventarioConjuge = valorInventario - valorInventarioCliente

                    valorInventarioDespesasAdvocaticiasCliente =
                        calculaValorPerc(valorInventarioDespesasAdvocaticias, linhaRecursosFinanceiros.percRecursosFinanceirosCliente)
                    valorInventarioCustasJudiciaisCliente =
                        calculaValorPerc(valorInventarioCustasJudiciais, linhaRecursosFinanceiros.percRecursosFinanceirosCliente)
                    valorInventarioItcmdCliente =
                        calculaValorPerc(valorInventarioItcmd, linhaRecursosFinanceiros.percRecursosFinanceirosCliente)

                    valorInventarioDespesasAdvocaticiasConjuge = valorInventarioDespesasAdvocaticias - valorInventarioDespesasAdvocaticiasCliente
                    valorInventarioCustasJudiciaisConjuge = valorInventarioCustasJudiciais - valorInventarioCustasJudiciaisCliente
                    valorInventarioItcmdConjuge = valorInventarioItcmd - valorInventarioItcmdCliente
                }

                /*//////
                if (temAporteDividendos(linhaTipoRecursoFinanceiro, linhaRecursosFinanceiros)) {

                    mesInicialAporteDividendosContribuicao = mesMesAno(linhaRecursosFinanceiros.mesAnoInicialAporteDividendos)
                    anoInicialAporteDividendosContribuicao = anoMesAno(linhaRecursosFinanceiros.mesAnoInicialAporteDividendos)

                    if (linhaRecursosFinanceiros.aporteDividendosAteAposentadoria) {

                        mesFinalAporteDividendosContribuicao = 12
                        anoFinalAporteDividendosContribuicao =
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente ||
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal
                                ?
                                    values.anoFinalVaiAposentarCliente
                                :
                                    values.anoFinalVaiAposentarConjuge

                    } else {

                        mesFinalAporteDividendosContribuicao = mesMesAno(linhaRecursosFinanceiros.mesAnoFinalAporteDividendos)
                        anoFinalAporteDividendosContribuicao = anoMesAno(linhaRecursosFinanceiros.mesAnoFinalAporteDividendos)
                    }

                    valorAporteDividendosContribuicao = calculaValorAporteDividendos(linhaRecursosFinanceiros)

                    valorAporteDividendosContribuicaoMensal = calculaValorMensal (valorAporteDividendosContribuicao,
                        linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos)

                    valorAporteDividendosMensalInventario =
                        round(valorAporteDividendosContribuicaoMensal *
                            ((percDespesasAdvocaticias + percCustasJudiciais + percItcmd) / 100), 2
                        )

                    saldo = calculaGraficoAcumulacaoMensal(mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                        mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, 0,
                            valorAporteDividendosMensalInventario, null, estudo.current.graficoAnos,
                                estudo.current.graficoSucessaoVitalicia,
                                    //estudo.current.graficoDespesasAcumuladas
                                    null, values, estudo, taxaRetornoAnualReal,
                                            taxaRetornoAnualRealAposAposentadoria, true, null, null, null)

                    if (mesFinalAporteDividendosContribuicao < 12) {

                        const indexGraficoAnos =
                            calculaIndexGraficoAnosIdades(estudo.current.graficoAnos,
                                anoFinalAporteDividendosContribuicao)

                        estudo.current.graficoSucessaoVitalicia[indexGraficoAnos] -= saldo

                        estudo.current.graficoDespesasAcumuladas[indexGraficoAnos] -= saldo
                    }

                    calculaGraficoAcumulacaoMensal(mesFinalAporteDividendosContribuicao === 12 ? 1 : mesFinalAporteDividendosContribuicao + 1,
                        mesFinalAporteDividendosContribuicao === 12 ? anoFinalAporteDividendosContribuicao + 1 : anoFinalAporteDividendosContribuicao,
                            null, anoFinal, saldo, 0, null,
                                estudo.current.graficoAnos, estudo.current.graficoSucessaoVitalicia,
                                    //estudo.current.graficoDespesasAcumuladas
                                    null, values, estudo, taxaRetornoAnualReal,
                                        taxaRetornoAnualRealAposAposentadoria, true, null, null, null)
                }
                */
                
                recursosFinanceirosTemp.push({
                    ordem: ("0000" + (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal ? 0 : linhaRecursosFinanceiros.familiar)).slice(-4) + 
                        ("000000000000000000000" + valor).slice(-21),
                    familiar: linhaRecursosFinanceiros.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricaoTipoRecursoFinanceiro: descricaoTipoRecursoFinanceiro,
                    valor: valor,
                    percDespesasAdvocaticias: percDespesasAdvocaticias,
                    percCustasJudiciais: percCustasJudiciais,
                    percDespesasAdvocaticiasCustasJudiciais: percDespesasAdvocaticias + percCustasJudiciais,
                    percItcmd: percItcmd,
                    valorInventario: valorInventario,
                    valorInventarioCliente: valorInventarioCliente,
                    valorInventarioConjuge: valorInventarioConjuge,
                })

                valorTotal += valor

                if (linhaTipoRecursoFinanceiro.somenteITCMD !== 'S') {

                    valorTotalDespesasAdvocaticiasCustasJudiciais += valor
                }

                valorClienteTotal += valorCliente
                valorConjugeTotal += valorConjuge

                valorInventarioTotal += valorInventario
                valorInventarioDespesasAdvocaticiasTotal += valorInventarioDespesasAdvocaticias
                valorInventarioCustasJudiciaisTotal += valorInventarioCustasJudiciais
                valorInventarioItcmdTotal += valorInventarioItcmd

                valorInventarioClienteTotal += valorInventarioCliente
                valorInventarioDespesasAdvocaticiasClienteTotal += valorInventarioDespesasAdvocaticiasCliente
                valorInventarioCustasJudiciaisClienteTotal += valorInventarioCustasJudiciaisCliente
                valorInventarioItcmdClienteTotal += valorInventarioItcmdCliente

                valorInventarioConjugeTotal += valorInventarioConjuge
                valorInventarioDespesasAdvocaticiasConjugeTotal += valorInventarioDespesasAdvocaticiasConjuge
                valorInventarioCustasJudiciaisConjugeTotal += valorInventarioCustasJudiciaisConjuge
                valorInventarioItcmdConjugeTotal += valorInventarioItcmdConjuge
            }
        })

        recursosFinanceirosTemp = sortAsc(recursosFinanceirosTemp)

        let familiarAnt = null

        const { aumentoLetra, aumentoLetraReport } = aumentoLetraSucessaoVitalicia(values) 

        recursosFinanceirosTemp.forEach( (linhaRecursosFinanceiros, index) => {
            if (linhaRecursosFinanceiros.familiar !== familiarAnt) {
                familiarAnt = linhaRecursosFinanceiros.familiar

                valor = 0
                valorInventario = 0
                valorInventarioCliente = 0
                valorInventarioConjuge = 0

                recursosFinanceirosTemp.forEach( (linhaRecursosFinanceirosTotal, index) => {
                    if (linhaRecursosFinanceirosTotal.familiar === linhaRecursosFinanceiros.familiar) {
                        valor += linhaRecursosFinanceirosTotal.valor
                        valorInventario += linhaRecursosFinanceirosTotal.valorInventario
                        valorInventarioCliente += linhaRecursosFinanceirosTotal.valorInventarioCliente
                        valorInventarioConjuge += linhaRecursosFinanceirosTotal.valorInventarioConjuge
                    }
                })

                recursosFinanceiros.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaRecursosFinanceiros.nomeCurtoFamiliar,
                            report, false, aumentoLetra, aumentoLetraReport, values),
                    valor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventario: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventario, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventarioCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventarioCliente, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                    valorInventarioConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorInventarioConjuge, report,
                            true, aumentoLetra, aumentoLetraReport, values),
                })
            }

            recursosFinanceiros.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("       " + 
                        linhaRecursosFinanceiros.descricaoTipoRecursoFinanceiro, report, false, false,
                            aumentoLetra, aumentoLetraReport, values),
                valor: 
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valor, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                percDespesasAdvocaticias:
                    formataTableCellBodyExibeDadosTexto(linhaRecursosFinanceiros.percDespesasAdvocaticias, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                percCustasJudiciais:
                    formataTableCellBodyExibeDadosTexto(linhaRecursosFinanceiros.percCustasJudiciais, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                percDespesasAdvocaticiasCustasJudiciais:
                    formataTableCellBodyExibeDadosTexto(
                        linhaRecursosFinanceiros.percDespesasAdvocaticiasCustasJudiciais, report,
                            false, aumentoLetra, aumentoLetraReport, values),
                percItcmd:
                    formataTableCellBodyExibeDadosTexto(linhaRecursosFinanceiros.percItcmd, report,
                        false, aumentoLetra, aumentoLetraReport, values),
                valorInventario: 
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorInventario, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                valorInventarioCliente: 
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorInventarioCliente, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
                valorInventarioConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorInventarioConjuge, report,
                        false, true, aumentoLetra, aumentoLetraReport, values),
            })
        })

        recursosFinanceiros.push({
            descricao:
                formataTableCellBodyExibeDadosSubTotal0Titulo(LABEL.recursosFinanceiros.toUpperCase() /* mudança da fonte: uppercase */,
                    report, false, aumentoLetra, aumentoLetraReport, values),
            valor:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorTotal, report, false, true,
                    aumentoLetra, aumentoLetraReport, values),
            valorInventario:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
            valorInventarioCliente:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioClienteTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
            valorInventarioConjuge:
                formataTableCellBodyExibeDadosSubTotal0Valor(valorInventarioConjugeTotal, report,
                    false, true, aumentoLetra, aumentoLetraReport, values),
        })

        //recursosFinanceiros.push({
        //    descricao:
        //        formataTableCellBodyExibeDadosSubTotalTitulo(" ", report, false, aumentoLetra, aumentoLetraReport, values)
        //})

        sucessaoVitaliciaTotalGeral.valor += valorTotal

        sucessaoVitaliciaTotalGeral.valorRecursosFinanceiros += valorTotal
        sucessaoVitaliciaTotalGeral.valorRecursosFinanceirosDespesasAdvocaticiasCustasJudiciais +=
            valorTotalDespesasAdvocaticiasCustasJudiciais
        
        sucessaoVitaliciaTotalGeral.valorClienteRecursosFinanceiros += valorClienteTotal
        sucessaoVitaliciaTotalGeral.valorConjugeRecursosFinanceiros += valorConjugeTotal

        sucessaoVitaliciaTotalGeral.valorInventario += valorInventarioTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticias += valorInventarioDespesasAdvocaticiasTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciais += valorInventarioCustasJudiciaisTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmd += valorInventarioItcmdTotal

        sucessaoVitaliciaTotalGeral.valorInventarioCliente += valorInventarioClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticiasCliente += valorInventarioDespesasAdvocaticiasClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciaisCliente += valorInventarioCustasJudiciaisClienteTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmdCliente += valorInventarioItcmdClienteTotal

        sucessaoVitaliciaTotalGeral.valorInventarioConjuge += valorInventarioConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioDespesasAdvocaticiasConjuge += valorInventarioDespesasAdvocaticiasConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioCustasJudiciaisConjuge += valorInventarioCustasJudiciaisConjugeTotal
        sucessaoVitaliciaTotalGeral.valorInventarioItcmdConjuge += valorInventarioItcmdConjugeTotal

        sucessaoVitaliciaTotalGeral.valorInventarioRecursosFinanceiros += valorInventarioTotal
    }

    estudo.current.inventario.push(...recursosFinanceiros)
}

export const calculaSucessaoVitaliciaDefault = ( values, estudo, report ) => {
    estudo.current.inventario = []

    let sucessaoVitaliciaTotalGeral =
        {
            valor: 0,

            valorPatrimonioImobilizado: 0,
            valorClientePatrimonioImobilizado: 0,
            valorConjugePatrimonioImobilizado: 0,

            valorRecursosFinanceiros: 0,
            valorRecursosFinanceirosDespesasAdvocaticiasCustasJudiciais: 0,
            valorClienteRecursosFinanceiros: 0,
            valorConjugeRecursosFinanceiros: 0,

            valorInventario: 0,
            valorInventarioDespesasAdvocaticias: 0,
            valorInventarioCustasJudiciais: 0,
            valorInventarioItcmd: 0,

            valorInventarioCliente: 0,
            valorInventarioDespesasAdvocaticiasCliente: 0,
            valorInventarioCustasJudiciaisCliente: 0,
            valorInventarioItcmdCliente: 0,

            valorInventarioConjuge: 0,
            valorInventarioDespesasAdvocaticiasConjuge: 0,
            valorInventarioCustasJudiciaisConjuge: 0,
            valorInventarioItcmdConjuge: 0,

            valorInventarioPatrimonioImobilizado: 0,

            valorInventarioRecursosFinanceiros: 0,
        }

    calculaSucessaoVitaliciaDefaultPatrimonioImobilizado(values, estudo, report, sucessaoVitaliciaTotalGeral)

    calculaSucessaoVitaliciaDefaultRecursosFinanceiros(values, estudo, report, sucessaoVitaliciaTotalGeral)

    values.sucessaoVitaliciaTotalGeral = sucessaoVitaliciaTotalGeral

    const { aumentoLetra, aumentoLetraReport } = aumentoLetraSucessaoVitalicia(values) 

    estudo.current.inventario.push({
        descricao:
            formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
        valor:
            formataTableCellBodyExibeDadosTotalValor(sucessaoVitaliciaTotalGeral.valor, report,
                true, aumentoLetra, aumentoLetraReport, values),
        valorInventario:
            formataTableCellBodyExibeDadosTotalValor(sucessaoVitaliciaTotalGeral.valorInventario, report,
                true, aumentoLetra, aumentoLetraReport, values),
        valorInventarioCliente:
            formataTableCellBodyExibeDadosTotalValor(sucessaoVitaliciaTotalGeral.valorInventarioCliente, report,
                true, aumentoLetra, aumentoLetraReport, values),
        valorInventarioConjuge:
            formataTableCellBodyExibeDadosTotalValor(sucessaoVitaliciaTotalGeral.valorInventarioConjuge, report,
                true, aumentoLetra, aumentoLetraReport, values),
    })

    if (!values.alterarValoresAutomaticosSucessaoVitaliciaCliente) {
        values.inventarioCliente = sucessaoVitaliciaTotalGeral.valorInventarioCliente
    }

    if (!values.alterarValoresAutomaticosSucessaoVitaliciaConjuge) {
        values.inventarioConjuge = sucessaoVitaliciaTotalGeral.valorInventarioConjuge
    }

    values.inventarioPatrimonioImobilizado = sucessaoVitaliciaTotalGeral.valorInventarioPatrimonioImobilizado
    values.inventarioRecursosFinanceiros = sucessaoVitaliciaTotalGeral.valorInventarioRecursosFinanceiros
}

export const calculaSucessaoVitalicia = ( values, estudo, report ) => {

    calculaSucessaoVitaliciaDefault(values, estudo, report)

    //////let valorInventarioAux

    values.emergencial = 0

    //////values.sucessaoVitaliciaComJuros = 0
    //////values.sucessaoVitaliciaSemJuros = 0

    values.sucessaoVitaliciaCliente = 0

    if (values.emergencialCliente) {

        values.emergencial += round(values.emergencialCliente, 2)

        values.sucessaoVitaliciaCliente += round(values.emergencialCliente, 2)
        //////values.sucessaoVitaliciaSemJuros += round(values.emergencialCliente, 2)
    }

    if (values.inventarioCliente) {

        //////valorInventarioAux =
        //////    calculaValorPerc(round(values.inventarioCliente, 2),
        //////        values.inventarioRecursosFinanceiros /
        //////            (values.inventarioPatrimonioImobilizado +  values.inventarioRecursosFinanceiros) * 100
        //////    )

        values.sucessaoVitaliciaCliente += round(values.inventarioCliente, 2)
        //////values.sucessaoVitaliciaComJuros += valorInventarioAux
        //////values.sucessaoVitaliciaSemJuros += round(values.inventarioCliente, 2) - valorInventarioAux
    }

    values.sucessaoVitaliciaConjuge = 0

    if (dadosCompletosConjuge(values)) {

        if (values.emergencialConjuge) {

            values.emergencial += round(values.emergencialConjuge, 2)

            values.sucessaoVitaliciaConjuge += round(values.emergencialConjuge, 2)
            //////values.sucessaoVitaliciaSemJuros += round(values.emergencialConjuge, 2)
        }
    
        if (values.inventarioConjuge) {

            ////valorInventarioAux =
            ////    calculaValorPerc(round(values.inventarioConjuge, 2),
            ////        values.inventarioRecursosFinanceiros /
            ////            (values.inventarioPatrimonioImobilizado +  values.inventarioRecursosFinanceiros) * 100
            ////    )

            values.sucessaoVitaliciaConjuge += round(values.inventarioConjuge, 2)
            //////values.sucessaoVitaliciaComJuros += valorInventarioAux
            //////values.sucessaoVitaliciaSemJuros += round(values.inventarioConjuge, 2) - valorInventarioAux
        }
    }
}

export const calculaSucessaoVitaliciaAtualizaGraficoSucessaoVitaliciaTaxaPonderada = ( values, estudo ) => {

    ////const anoInicial = ano(values.dataSimulacaoEstudo)
    ////const anoFinal = values.anoFinalVitalicioClienteConjuge

    //if (values.morteInvalidezEstudo !== "I") { // Estava gerando erro no gráfico da Projeção da Sucessão Vitalícia

    //////    calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, values.sucessaoVitaliciaComJuros, 0, null,
    //////        estudo.current.graficoAnos, estudo.current.graficoSucessaoVitalicia,
    //////            /*estudo.current.graficoDespesasAcumuladas*/null,
    //////                values, estudo, /*values.naoAplicarTaxaJurosSeguroVida ? 0 : */values.taxaRetornoAnualRealPonderada,
    //////                    /*values.naoAplicarTaxaJurosSeguroVida ? 0 : */values.taxaRetornoAnualRealAposAposentadoriaPonderada,
    //////                        true, null, null, null)

    //////  calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, values.sucessaoVitaliciaSemJuros, 0, null,
    //////        estudo.current.graficoAnos, estudo.current.graficoSucessaoVitalicia,
    //////            /*estudo.current.graficoDespesasAcumuladas*/null, values, estudo, 0, 0, true, null, null, null)

    //}

    const percInventarioRecFin = values.inventarioRecursosFinanceiros / values.sucessaoVitaliciaTotalGeral.valorRecursosFinanceiros

    estudo.current.graficoIndependenciaFinanceiraSituacaoAtual?.forEach((linha, ind) => {

        let valorRecursoFinanceiro = linha

        estudo.current.graficoRecursosFinanceirosAcumulados?.forEach( (linha2, ind2) => {

            const idTipoRecursoFinanceiro = Number.parseInt(linha2.id.substring(0, linha2.id.indexOf('-')))

            const linhaTipoRecursoFinanceiroList = pesquisaList(tipoRecursoFinanceiroList, idTipoRecursoFinanceiro)

            const naoInventariar = linha2.id.indexOf('-S-') !== -1

            if (linhaTipoRecursoFinanceiroList.inventario === "N" || naoInventariar) {

                valorRecursoFinanceiro -= linha2.serie[ind]
            }
        })
        
        estudo.current.graficoSucessaoVitaliciaRecursosFinanceiros[ind] = round(valorRecursoFinanceiro * percInventarioRecFin, 2)
    })
}