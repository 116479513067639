import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    ano,
    mesMesAno,
    anoMesAno,
    formataMesAnoComBarra,
    formataMesAnoTextoComBarra,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosTextoIdade,
    formataTableCellBodyExibeDadosTituloDestaque2Titulos,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosPerc,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosSubTotalPerc,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
    pesquisaDescricaoList,
 } from '../../../business-rules/List/ListService'

import tipoReceitaMensalList from '../../../business-rules/List/tipoReceitaMensalList'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import {
    dadosCompletosConjuge,
    calculaValorMensal,
    calculaIntervaloTempoAnos,
    calculaIntervaloTempoAnosMorteAteVitalicio,
} from '../cliente-form/ClienteService'

import {
    possuiFamiliares,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosLinhaReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    calculaDescricao,
    formataValorTipoPeriodicidade,
    calculaIdadeCorrespondenteAno,
} from './EstudoService'

import {
    inicializaSerieGrafico,
    calculaGraficoValorPresente,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

export const calculaAnoInicialReceitaMensalClienteConjuge = ( values, linhaReceitaMensal, linhaTipoReceitaMensal ) => {
    
    let anoAtual = ano(values.dataSimulacaoEstudo)

    let anoInicial = null

    if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente) {

        anoInicial =
            linhaTipoReceitaMensal.tipoCalculoAcumulado === "PA"
                ?
                    values.anoFinalVaiAposentarCliente + 1

                :
                    linhaTipoReceitaMensal.tipoCalculoAcumulado === "II"
                        ?
                            values.anoInicioInssCliente
                        :
                            anoAtual
    } else {

        if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge) {

            anoInicial =
                linhaTipoReceitaMensal.tipoCalculoAcumulado === "PA"
                    ?
                        values.anoFinalVaiAposentarConjuge + 1
                    :
                        linhaTipoReceitaMensal.tipoCalculoAcumulado === "II"
                            ?
                                values.anoInicioInssConjuge
                            :
                                anoAtual
        }
    }

    return anoInicial
}

export const calculaAnoFinalReceitaMensalClienteConjuge = ( values, linhaReceitaMensal, linhaTipoReceitaMensal ) => {
    let anoFinal = null

    if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente) {

        anoFinal =
            linhaTipoReceitaMensal.tipoCalculoAcumulado === "AA"
                ?
                    values.anoFinalVaiAposentarCliente
                :
                    values.anoFinalVitalicioClienteConjuge
    } else {

        if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge) {

            anoFinal =
                linhaTipoReceitaMensal.tipoCalculoAcumulado === "AA"
                    ?
                        values.anoFinalVaiAposentarConjuge
                    :
                        values.anoFinalVitalicioClienteConjuge
        }
    }

    return anoFinal
}

const calculaAnoFinalClienteConjugeReceitaMensalClienteConjuge = ( values, linhaReceitaMensal, linhaTipoReceitaMensal ) => {
    let anoFinal = null

    if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente) {

        anoFinal =
            linhaTipoReceitaMensal.tipoCalculoAcumulado === "AA"
                ?
                    values.anoFinalVaiAposentarCliente
                :
                    values.anoFinalClienteConjuge
    } else {
        if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge) {

            anoFinal =
                linhaTipoReceitaMensal.tipoCalculoAcumulado === "AA"
                    ?
                        values.anoFinalVaiAposentarConjuge
                    :
                        values.anoFinalClienteConjuge
        }
    }

    return anoFinal
}

export const calculaGarantiasReceitaMensal = (values, estudo, report) => {
    let receitaMensalTemp = []
    let receitaMensal = []
    let receitaMensalDetalhadaTemp = []
    let receitaMensalDetalhada = []
    let panelGarantiasAtuaisReceitaMensal

    if (values.receitaMensal) {
        let linhaTipoReceitaMensal = ''
        let descricaoTipoReceitaMensal = null

        let familiaresListAux = familiaresList(values)
        let linhaFamiliaresList = ''
        let nomeCurtoFamiliar = null

        let indexGraficoReceitaAnual = ''
        let indexGraficoReceitaAnualMedia = ''

        let valor
        let valorMensalAux = 0
        let valorMensal = 0
        let valorEstimado = 0
        let mesInicial
        let anoInicial = ''
        let mesFinal
        let anoFinal = ''
        let mesFinalClienteConjuge
        let anoFinalClienteConjuge = ''
        let valorAcumulado = 0
        let valorGarantiaCliente = 0
        let valorGarantiaConjuge = 0

        let valorMensalTotal = 0
        let valorAcumuladoTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorGarantiaConjugeTotal = 0
        
        values.receitaMensal.forEach( (linhaReceitaMensal, index) => {
            if (
                    dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaReceitaMensal.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.receitaMensal + '/' + linhaReceitaMensal.id).id
            ) {
                
                linhaTipoReceitaMensal = pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal)

                descricaoTipoReceitaMensal = calculaDescricao(linhaTipoReceitaMensal.descricao, linhaReceitaMensal.descricaoOutras)

                linhaFamiliaresList = pesquisaList(familiaresListAux, linhaReceitaMensal.familiar)

                nomeCurtoFamiliar = linhaFamiliaresList.descricao

                valor = round(linhaReceitaMensal.valor, 2)
                valorMensalAux = calculaValorMensal (linhaReceitaMensal.valor, linhaReceitaMensal.tipoPeriodicidadeRecebto)
                
                if (
                        linhaTipoReceitaMensal.totalizaEntradaDados === "S" &&
                        (
                            linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente ||
                            linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge
                        ) &&
                        (
                            !linhaReceitaMensal.alterarTipoCalculoAcumulado ||
                            (
                                linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                                anoMesAno(linhaReceitaMensal.mesAnoInicial) === estudo.current.anoAtual
                            )
                        ) && (
                            linhaTipoReceitaMensal.tipoCalculoAcumulado !== "PA" ||
                            (
                                linhaTipoReceitaMensal.tipoCalculoAcumulado === "PA" &&
                                linhaFamiliaresList.idade > linhaFamiliaresList.idadeVaiAposentar
                            )
                        )
                ) {

                    valorMensal = valorMensalAux
                    valorEstimado = 0

                } else {

                    valorMensal = 0
                    valorEstimado = valor
                }

                if (!linhaReceitaMensal.alterarTipoCalculoAcumulado) {

                    anoInicial =
                        calculaAnoInicialReceitaMensalClienteConjuge(values, linhaReceitaMensal, linhaTipoReceitaMensal)

                    mesInicial = anoInicial === estudo.current.anoAtual ? estudo.current.mesAtual : 1

                    mesFinal = 12

                    anoFinal =
                        calculaAnoFinalReceitaMensalClienteConjuge(values, linhaReceitaMensal, linhaTipoReceitaMensal)

                    mesFinalClienteConjuge = 12
                        
                    anoFinalClienteConjuge =
                        calculaAnoFinalClienteConjugeReceitaMensalClienteConjuge(values, linhaReceitaMensal,
                            linhaTipoReceitaMensal)
                
                } else {

                    mesInicial = mesMesAno(linhaReceitaMensal.mesAnoInicial)
                    anoInicial = anoMesAno(linhaReceitaMensal.mesAnoInicial)

                    if (linhaReceitaMensal.receitaMensalAteAposentadoria) {

                        mesFinal = 12
                        anoFinal = calculaAnoFinalReceitaMensalClienteConjuge(values, linhaReceitaMensal, linhaTipoReceitaMensal)

                    } else {

                        mesFinal = mesMesAno(linhaReceitaMensal.mesAnoFinal)
                        anoFinal = anoMesAno(linhaReceitaMensal.mesAnoFinal)
                    }
                   

                    mesFinalClienteConjuge = null

                    anoFinalClienteConjuge =
                        calculaAnoFinalClienteConjugeReceitaMensalClienteConjuge(values, linhaReceitaMensal,
                            linhaTipoReceitaMensal)

                    if (anoFinalClienteConjuge > anoFinal) {

                        mesFinalClienteConjuge = mesFinal

                        anoFinalClienteConjuge = anoFinal
                    }
                }

                valorAcumulado = 0
                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                if (
                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente ||
                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge
                ) {

                    if (linhaTipoReceitaMensal.cobreSobrevivencia === "S") {

                        indexGraficoReceitaAnual =
                            inicializaSerieGrafico(values, estudo.current.graficoReceitaAnual, linhaTipoReceitaMensal,
                                linhaReceitaMensal.id, descricaoTipoReceitaMensal + LABEL.traco + nomeCurtoFamiliar)

                        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                            valor, linhaReceitaMensal.tipoPeriodicidadeRecebto, estudo.current.graficoAnos,
                                estudo.current.graficoReceitaAnual[indexGraficoReceitaAnual].serie,
                                        estudo.current.graficoReceitaAnualTotal,
                                            values, estudo, 0, 0, false, null, null, null)

                        indexGraficoReceitaAnualMedia =
                            inicializaSerieGrafico(values, estudo.current.graficoReceitaAnualMedia, linhaTipoReceitaMensal,
                                linhaReceitaMensal.id, descricaoTipoReceitaMensal + LABEL.traco + nomeCurtoFamiliar)

                        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                            valorMensalAux, null, estudo.current.graficoAnos,
                                estudo.current.graficoReceitaAnualMedia[indexGraficoReceitaAnualMedia].serie,
                                    estudo.current.graficoReceitaAnualTotalMedia,
                                        values, estudo, 0, 0, false, null, null, null)
                    }

                    valorAcumulado = estudo.current.graficoReceitaMensal[0]

                    calculaGraficoValorPresente(mesInicial, anoInicial, mesFinalClienteConjuge,
                        anoFinalClienteConjuge, valor, linhaReceitaMensal.tipoPeriodicidadeRecebto, estudo.current.graficoAnos,
                            estudo.current.graficoReceitaMensal, null, values, estudo,
                                values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                    values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                        values.taxaRetornoAnualRealAposAposentadoria, null, null, true
                    )

                    valorAcumulado = round(estudo.current.graficoReceitaMensal[0] - valorAcumulado, 2)

                    if (
                            (
                                (
                                    values.morteInvalidezEstudo === "A" ||
                                    values.morteInvalidezEstudo === "M"
                                ) && 
                                (
                                    (
                                        linhaTipoReceitaMensal.cobreMorte === "S" &&
                                        (
                                            dadosCompletosConjuge(values) ||
                                            possuiFamiliares(values)
                                        )
                                    ) ||
                                    linhaTipoReceitaMensal.cobreMorteSolteiroSemDependente === "S"
                                )
                            ) ||
                            (
                                values.morteInvalidezEstudo === "I" && 
                                linhaTipoReceitaMensal.cobreInvalidez === "S"
                            )
                    ) {

                        if (
                                (
                                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente || 
                                    linhaTipoReceitaMensal.cobreMorteInvalidezClienteEConjuge === "S"
                                ) &&
                                (
                                    (
                                        values.morteInvalidezEstudo === "I" &&
                                        !linhaReceitaMensal.naoConsiderarGarantiaInvalidezCliente
                                    ) ||
                                    (
                                        values.morteInvalidezEstudo !== "I" &&
                                        !linhaReceitaMensal.naoConsiderarGarantiaMorteCliente
                                    )
                                )
                        ) {

                            valorGarantiaCliente = valorAcumulado
                        }

                        if (
                                (
                                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge ||
                                    linhaTipoReceitaMensal.cobreMorteInvalidezClienteEConjuge === "S"
                                ) &&
                                (
                                    (
                                        values.morteInvalidezEstudo === "I" &&
                                        !linhaReceitaMensal.naoConsiderarGarantiaInvalidezConjuge
                                    ) ||
                                    (
                                        values.morteInvalidezEstudo !== "I" &&
                                        !linhaReceitaMensal.naoConsiderarGarantiaMorteConjuge
                                    )
                                )
                        ) {

                            valorGarantiaConjuge = valorAcumulado
                        }

                    } else {

                        if (linhaTipoReceitaMensal.cobreMorteInvalidezClienteOuConjuge === "S") {

                            if (
                                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente &&
                                    (
                                        (
                                            values.morteInvalidezEstudo === "I" &&
                                            !linhaReceitaMensal.naoConsiderarGarantiaInvalidezConjuge
                                        ) ||
                                        (
                                            values.morteInvalidezEstudo !== "I" &&
                                            !linhaReceitaMensal.naoConsiderarGarantiaMorteConjuge
                                        )
                                    )
                            ) {

                                valorGarantiaConjuge = valorAcumulado
                            }

                            if (
                                    linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge &&
                                    (
                                        (
                                            values.morteInvalidezEstudo === "I" &&
                                            !linhaReceitaMensal.naoConsiderarGarantiaInvalidezCliente
                                        ) ||
                                        (
                                            values.morteInvalidezEstudo !== "I" &&
                                            !linhaReceitaMensal.naoConsiderarGarantiaMorteCliente
                                        )
                                    )
                            ) {

                                valorGarantiaCliente = valorAcumulado
                            }
                        }
                    }
                    
                    if (linhaTipoReceitaMensal.cobreSobrevivencia === "S") {

                        receitaMensalDetalhadaTemp.push({
                            ordem: ("0000" + linhaReceitaMensal.familiar).slice(-4) + 
                                ("0000" +
                                    (
                                        linhaReceitaMensal.idReceitaMensal
                                            ?
                                                pesquisaList(values.receitaMensal,
                                                    linhaReceitaMensal.idReceitaMensal).tipoReceitaMensal
                                            :
                                                linhaReceitaMensal.tipoReceitaMensal)
                                    ).slice(-4) +
                                ("0000" + linhaReceitaMensal.tipoReceitaMensal).slice(-4),
                            familiar: linhaReceitaMensal.familiar,
                            nomeCurtoFamiliar: nomeCurtoFamiliar, 
                            descricaoTipoReceitaMensal: descricaoTipoReceitaMensal,
                            valor: valor,
                            tipoPeriodicidadeRecebto: linhaReceitaMensal.tipoPeriodicidadeRecebto,
                            valorMensal: valorMensal,
                            mesAnoInicial: formataMesAnoComBarra(mesInicial, anoInicial),
                            idadeInicial: calculaIdadeCorrespondenteAno(values, estudo, anoInicial, linhaReceitaMensal.familiar),
                            mesAnoFinal: formataMesAnoComBarra(mesFinal, anoFinal),
                            idadeFinal: calculaIdadeCorrespondenteAno(values, estudo, anoFinal, linhaReceitaMensal.familiar),
                        })
                    }
                }

                if (
                        linhaTipoReceitaMensal.totalizaEntradaDados === "N" &&
                        valorGarantiaCliente === 0 &&
                        valorGarantiaConjuge === 0
                ) {

                    valorAcumulado = 0
                }

                receitaMensalTemp.push({
                    ordem: ("0000" + linhaReceitaMensal.familiar).slice(-4) + 
                        ("0000" +
                            (
                                linhaReceitaMensal.idReceitaMensal
                                    ?
                                        pesquisaList(values.receitaMensal,
                                            linhaReceitaMensal.idReceitaMensal).tipoReceitaMensal
                                    :
                                        linhaReceitaMensal.tipoReceitaMensal)
                            ).slice(-4) +
                        ("0000" + linhaReceitaMensal.tipoReceitaMensal).slice(-4),
                    familiar: linhaReceitaMensal.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar, 
                    descricaoTipoReceitaMensal: descricaoTipoReceitaMensal,
                    valor: valor,
                    tipoPeriodicidadeRecebto: linhaReceitaMensal.tipoPeriodicidadeRecebto,
                    valorMensal: valorMensal,
                    mesAnoInicial: linhaReceitaMensal.mesAnoInicial,
                    valorEstimado: valorEstimado,
                    anoFinal: anoFinalClienteConjuge,
                    anosProtegerProtegido: calculaIntervaloTempoAnos(values, anoFinalClienteConjuge, anoInicial),
                    valorAcumulado: valorAcumulado,
                    valorGarantiaCliente: valorGarantiaCliente,
                    valorGarantiaConjuge: valorGarantiaConjuge,
                    idReceitaMensal: linhaReceitaMensal.idReceitaMensal,
                })

                valorMensalTotal = valorMensalTotal + valorMensal
                valorAcumuladoTotal = valorAcumuladoTotal + valorAcumulado
                valorGarantiaClienteTotal = valorGarantiaClienteTotal + valorGarantiaCliente
                valorGarantiaConjugeTotal = valorGarantiaConjugeTotal + valorGarantiaConjuge
            }
        })

        receitaMensalTemp = sortAsc(receitaMensalTemp)

        estudo.current.graficoReceitaAnual = sortAsc(estudo.current.graficoReceitaAnual)

        estudo.current.graficoReceitaAnualMedia = sortAsc(estudo.current.graficoReceitaAnualMedia)

        let familiarAnt = null

        receitaMensalTemp.forEach( (linhaReceitaMensal, index) => {

            if (linhaReceitaMensal.familiar !== familiarAnt) {
                
                familiarAnt = linhaReceitaMensal.familiar

                valorMensal = 0
                valorAcumulado = 0
                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                receitaMensalTemp.forEach( (linhaReceitaMensalTotal, index) => {
                    if (linhaReceitaMensalTotal.familiar === linhaReceitaMensal.familiar) {
                        valorMensal = valorMensal + 
                            linhaReceitaMensalTotal.valorMensal
                        valorAcumulado = valorAcumulado + 
                            linhaReceitaMensalTotal.valorAcumulado
                        valorGarantiaCliente = valorGarantiaCliente + 
                            linhaReceitaMensalTotal.valorGarantiaCliente
                        valorGarantiaConjuge = valorGarantiaConjuge + 
                            linhaReceitaMensalTotal.valorGarantiaConjuge
                    }
                })

                receitaMensal.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaReceitaMensal.nomeCurtoFamiliar, report),
                    valorMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report),
                })
            }

            receitaMensal.push({
                descricao: 
                    linhaReceitaMensal.valorEstimado && linhaReceitaMensal.mesAnoInicial
                        ?
                            formataTableCellBodyExibeDadosTituloDestaque2Titulos("          " + 
                                (linhaReceitaMensal.idReceitaMensal ? "          " : "") +
                                    linhaReceitaMensal.descricaoTipoReceitaMensal,
                                        LABEL.inicio + ' ' + formataMesAnoTextoComBarra(linhaReceitaMensal.mesAnoInicial), report)
                        :
                            formataTableCellBodyExibeDadosTitulo("          " + 
                                (linhaReceitaMensal.idReceitaMensal ? "          " : "") +
                                    linhaReceitaMensal.descricaoTipoReceitaMensal, report),
                valorMensal:
                    linhaReceitaMensal.valorMensal === 0
                        ?
                            ''
                        :
                            formataValorTipoPeriodicidade(linhaReceitaMensal.valor, 2, false, false,
                                linhaReceitaMensal.tipoPeriodicidadeRecebto, false, report),
                valorEstimado:
                    linhaReceitaMensal.valorEstimado === 0
                        ?
                            ''
                        :
                            formataValorTipoPeriodicidade(linhaReceitaMensal.valorEstimado, 2, false, false,
                                linhaReceitaMensal.tipoPeriodicidadeRecebto, false, report),
                anoFinal:
                    formataTableCellBodyExibeDadosTexto(linhaReceitaMensal.anoFinal, report),
                anosProtegerProtegido:
                    formataTableCellBodyExibeDadosTexto(linhaReceitaMensal.anosProtegerProtegido, report),
                valorAcumulado:
                    formataTableCellBodyExibeDadosValor(linhaReceitaMensal.valorAcumulado, report),
                valorGarantiaCliente:
                    formataTableCellBodyExibeDadosValor(linhaReceitaMensal.valorGarantiaCliente, report),
                valorGarantiaConjuge:
                    formataTableCellBodyExibeDadosValor(linhaReceitaMensal.valorGarantiaConjuge, report),
            })
        })

        receitaMensal.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorMensal:
                formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
            valorAcumulado:
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorGarantiaCliente:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report),
            valorGarantiaConjuge:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report),
        })



        receitaMensalDetalhadaTemp = sortAsc(receitaMensalDetalhadaTemp)

        familiarAnt = null

        receitaMensalDetalhadaTemp.forEach( (linhaReceitaMensal, index) => {

            if (linhaReceitaMensal.familiar !== familiarAnt) {
                
                familiarAnt = linhaReceitaMensal.familiar

                valorMensal = 0

                receitaMensalDetalhadaTemp.forEach( (linhaReceitaMensalTotal, index) => {

                    if (linhaReceitaMensalTotal.familiar === linhaReceitaMensal.familiar) {

                        valorMensal += linhaReceitaMensalTotal.valorMensal
                    }
                })

                receitaMensalDetalhada.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaReceitaMensal.nomeCurtoFamiliar, report),
                    valorMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                    percMensal:
                        formataTableCellBodyExibeDadosSubTotalPerc(round(valorMensal / valorMensalTotal * 100, 2), report),
                })
            }

            receitaMensalDetalhada.push({
                descricao: formataTableCellBodyExibeDadosTitulo("          " + 
                    (linhaReceitaMensal.idReceitaMensal ? "          " : "") +
                        linhaReceitaMensal.descricaoTipoReceitaMensal, report),
                valor: formataTableCellBodyExibeDadosValor(linhaReceitaMensal.valor, report),
                descricaoTipoPeriodicidadeRecebto: formataTableCellBodyExibeDadosTexto(
                    pesquisaDescricaoList(tipoPeriodicidadeList(false, false), linhaReceitaMensal.tipoPeriodicidadeRecebto), report),
                valorMensal: formataTableCellBodyExibeDadosValor(linhaReceitaMensal.valorMensal, report),
                percMensal: formataTableCellBodyExibeDadosPerc(round(linhaReceitaMensal.valorMensal / valorMensalTotal * 100, 2), report),
                mesAnoInicial: formataTableCellBodyExibeDadosTextoIdade(
                    linhaReceitaMensal.mesAnoInicial, linhaReceitaMensal.idadeInicial, report),
                mesAnoFinal: formataTableCellBodyExibeDadosTextoIdade(
                    linhaReceitaMensal.mesAnoFinal, linhaReceitaMensal.idadeFinal, report),
            })
        })

        receitaMensalDetalhada.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorMensal:
                formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
        })
    

        
        panelGarantiasAtuaisReceitaMensal = {
            descricao: LABEL.receitaMensal,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: valorGarantiaClienteTotal,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: valorGarantiaConjugeTotal,
            anosProtegerProtegido: calculaIntervaloTempoAnosMorteAteVitalicio(values),
        }

    } else {

        panelGarantiasAtuaisReceitaMensal = {
            descricao: LABEL.receitaMensal,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.receitaMensal = receitaMensal

    estudo.current.receitaMensalDetalhada = receitaMensalDetalhada

    estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoReceitaMensal] = panelGarantiasAtuaisReceitaMensal
}