import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    ano,
    mes,
    dia,
    dataAnoMesDia,
    converteDataExtensoData,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

//import {
//    calculaValorAnual,
//} from '../cliente-form/ClienteService'

import {
    trataTexto,
    trataValorTexto,
    trataTelefone,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    incluiErroTipoCoberturaSeguroVida,
    identificaTipoCoberturaSeguroVida,
    identificaStatusCobertura,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoPDFAvulso,
    obtemDadoXLSX,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    numeroCotacaoAutomatico,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaPropostaMAG = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {


    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)


    PDFTxt = PDFTxt.replace(fimLinha + 'Complemento:' + fimLinha + 'Bairro:' + fimLinha, 
        fimLinha + 'Complemento:' + fimLinha + '' + fimLinha + 'Bairro:' + fimLinha)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Antecipação do pagamento por tempo:' + espaco, ': ' +
        'Antecipação do pagamento por tempo -' + espaco)
    PDFTxt = PDFTxt.replaceAll(espaco + 'Benefício (R$) Contribuição (R$)' + fimLinha, ': ')
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Benefício (R$) Contribuição (R$)' + fimLinha, ': ')



    obtemDadoPDF(PDFTxt, jsonRegistros, 'NÚMERO DA PROPOSTA:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'DADOS DO PROPONENTE' + fimLinha + 'Nome Completo:' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento:' + fimLinha, fimLinha, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo biológico:' + fimLinha, fimLinha,
            HARDCODE.importacaoSexoSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].sexoSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo:' + fimLinha, fimLinha,
            HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF:' + fimLinha, fimLinha, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Ocupação Principal:' + fimLinha, fimLinha,
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Renda Mensal Bruta:' + fimLinha, fimLinha,
        HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço Residencial:' + fimLinha,
        fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'N°:' + fimLinha,
        fimLinha, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Complemento:' + fimLinha,
        fimLinha, HARDCODE.importacaoComplementoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro:' + fimLinha, fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade:' + fimLinha, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'UF:' + fimLinha, fimLinha,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CEP:' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone Celular:' + fimLinha, fimLinha,
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'E-mail:' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'DADOS DOS PLANOS' + fimLinha,
        fimLinha + 'Contribuição:' + espaco,
            HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Periodicidade:' + fimLinha, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data e hora da assinatura:' + fimLinha, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da assinatura:' + fimLinha, fimLinha,
            HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Código do Corretor:' + espaco, fimLinha,
        HARDCODE.importacaoCodigoAgente, true, null, erros, nomeArquivo)

    const usuarioClienteSelecionado =
        idAgenteTimeSeguradora(timeSeguradoraAux, jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)

    if (usuarioClienteSelecionado) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

            const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
            let indexLinhasCoberturaAux = 0

            const linhasCobertura = []
            let indexLinhasCobertura = -1

            while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

                if (
                        linhasCoberturaAux[indexLinhasCoberturaAux].indexOf('SUBTOTAL:') === -1 &&
                        linhasCoberturaAux[indexLinhasCoberturaAux].indexOf('PRAZO EM ANOS:') === -1 &&
                        linhasCoberturaAux[indexLinhasCoberturaAux].indexOf('DECRÉSCIMO EM ANOS:') === -1
                ) {

                        if (
                                linhasCoberturaAux[indexLinhasCoberturaAux].substring(0, 1) === '(' ||
                                !isNaN(linhasCoberturaAux[indexLinhasCoberturaAux].slice(-1))
                        ) {

                            indexLinhasCobertura++

                            linhasCobertura.push('')
                        }

                        linhasCobertura[indexLinhasCobertura] +=
                            (linhasCobertura[indexLinhasCobertura] ? ' - ' : '' ) + linhasCoberturaAux[indexLinhasCoberturaAux]
                }

                indexLinhasCoberturaAux++
            }



            const coberturas = []

            let numeroCobertura
            let codigoCobertura
            let descricaoCobertura
            let dadosCobertura
            let valorCS
            let valorPremioLiquido
            let valorIof
            let valorPremio
            let classeAjusteRisco = ''
            let valorPremioExtra = ''
            let duracaoPremioExtra = ''
            let dataTerminoCobertura
            let valorRendaMensal = ''

            indexLinhasCobertura = 0

            let descricaoCoberturaBase = ''

            while (indexLinhasCobertura < linhasCobertura.length) {

                dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
                
                valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 2])
                valorPremioLiquido = ''
                valorIof = ''
                valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 1])

                descricaoCobertura = ''

                indexLinhasCoberturaAux = 0

                while (indexLinhasCoberturaAux < dadosCobertura.length - 2) {
                
                    descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
                
                    indexLinhasCoberturaAux++
                }

                if (descricaoCobertura.substring(0, 1) === '(') {
                    
                    descricaoCoberturaBase = descricaoCobertura.substring(0, descricaoCobertura.indexOf(':'))

                } else {

                    descricaoCobertura = descricaoCoberturaBase + ': ' + descricaoCobertura
                }

                const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                    jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida, null,
                    valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
                        dataTerminoCobertura, valorRendaMensal)

                indexLinhasCobertura += 1
            }

            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

        }

    } else {

        erros.push(MESSAGE().agenteNaoEncontradoProposta
            .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                .replace("$codigoAgente$", jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)
        )
    }


    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasMAG = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {


    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (
                XLSX[linha][0] &&
                XLSX[linha][0] !== 'Total' &&
                ('' + XLSX[linha][0]).indexOf('Filtros aplicados:') === -1
        ) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][40])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoStatusProposta, true,
                linhaIntegracao.statusProposta, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)


            obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoTipoPeriodicidadePagtoPremioInteiro, true,
                linhaIntegracao.tipoPeriodicidadePagtoPremio, erros, nomeArquivo)
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = 
                jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremioInteiro


            obtemDadoXLSX(XLSX, jsonRegistros, linha, 21, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 26, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)

            if (!jsonRegistros.jsonRegistros[indexJsonRegistros].observacao) {

                obtemDadoXLSX(XLSX, jsonRegistros, linha, 27, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)
            }
            

            if (usuarioClienteSelecionado) {
               
                    jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
                 
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][40])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }
    

    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasMAGNovoLayout = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {


    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][2]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][/*50*//*56*/57].split(espaco)[0])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoStatusProposta, true,
                linhaIntegracao.statusProposta, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, /*14*/15, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, /*17*/18, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true,
                null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, /*20*/21, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, /*32*//*34*/35, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)

            const observacao = jsonRegistros.jsonRegistros[indexJsonRegistros].observacao

            obtemDadoXLSX(XLSX, jsonRegistros, linha, /*30*//*32*/33, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)

            if (observacao) {

                if (jsonRegistros.jsonRegistros[indexJsonRegistros].observacao) {

                    jsonRegistros.jsonRegistros[indexJsonRegistros].observacao += ' / ' + observacao

                } else {

                    jsonRegistros.jsonRegistros[indexJsonRegistros].observacao = observacao
                }
            }
            

            if (usuarioClienteSelecionado) {
               
                    jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
                 
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][/*50*//*56*/57].split(espaco)[0])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }

    

    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasImplantacaoMAG = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {


    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (
                XLSX[linha][0] &&
                XLSX[linha][0] !== 'Total' &&
                ('' + XLSX[linha][0]).indexOf('Filtros aplicados:') === -1
        ) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][40])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 28, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 30, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 29, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 31, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 36, HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 34, HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 33, HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 35, HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

            jsonRegistros.jsonRegistros[indexJsonRegistros].celularSeguradoCliente = trataTelefone('' + XLSX[linha][38] + XLSX[linha][39])

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 37, HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 32, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)


            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoStatusProposta, true,
                linhaIntegracao.statusProposta, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5, HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)


            obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoTipoPeriodicidadePagtoPremioInteiro, true,
                linhaIntegracao.tipoPeriodicidadePagtoPremio, erros, nomeArquivo)
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = 
                jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremioInteiro


            obtemDadoXLSX(XLSX, jsonRegistros, linha, 21, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 26, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)

            if (!jsonRegistros.jsonRegistros[indexJsonRegistros].observacao) {

                obtemDadoXLSX(XLSX, jsonRegistros, linha, 27, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)
            }
            


            if (usuarioClienteSelecionado) {
               
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
    
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][40])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }
    

    return jsonRegistros.jsonRegistros
}



export const importaApoliceMAGPrivateSolutions = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    

    PDFTxt = PDFTxt.replace(fimLinha + '2415SEGURO DE ASSISTENCIA FUNERAL' + fimLinha + 'LUXO INDIVIDUAL' + fimLinha,
        fimLinha + '2415SEGURO DE ASSISTENCIA FUNERAL LUXO INDIVIDUAL' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '2418SEGURO ASSISTENCIA FUNERAL' + fimLinha + 'FAMILIAR SUPERLUXO' + fimLinha,
        fimLinha + '2418SEGURO ASSISTENCIA FUNERAL FAMILIAR SUPERLUXO' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '538 INVALIDEZ POR ACIDENTE (COB.' + fimLinha + 'PRINCIPAL)' + fimLinha,
        fimLinha + '538 INVALIDEZ POR ACIDENTE (COB.PRINCIPAL)' + espaco)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta nº:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do Segurado:' + espaco, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome Social:' + espaco, fimLinha,
            HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento:' + espaco, fimLinha,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Matrícula:' + espaco, fimLinha,
    //    HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
    jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF:' + espaco, fimLinha,
        HARDCODE.importacaoCpfSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].cpfSeguradoCliente) {

        jsonRegistros.indexInicial = 0

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF:' + espaco, fimLinha,
            HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)
    }

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da Emissão:' + espaco, espaco, 
    //    HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Período de' + fimLinha + 'Vigência' + fimLinha,
        fimLinha + 'Prêmio Líquido:' + espaco, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Periodicidade de Pagamento:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, HARDCODE.idStatusApoliceAtiva, HARDCODE.importacaoStatusApolice, true, null, erros, nomeArquivo)



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)

        let indexLinhasCobertura = 0

        const coberturas = []

        let indexPesquisaCobertura
        let posTipoCoberturaSeguroVida

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        while (indexLinhasCobertura < linhasCobertura.length) {

            indexPesquisaCobertura = 0

            descricaoCobertura = ''

            dadosCobertura = ''

            if (linhasCobertura[indexLinhasCobertura].length < 50) {

                indexLinhasCobertura += 2

                linhasCobertura[indexLinhasCobertura] = linhasCobertura[indexLinhasCobertura - 2] + ' ' +
                    linhasCobertura[indexLinhasCobertura - 1] + ' ' + linhasCobertura[indexLinhasCobertura]
            }

            while (indexPesquisaCobertura < linhaIntegracao.tipoCoberturaSeguroVida.length) {

                if (!descricaoCobertura) {

                    posTipoCoberturaSeguroVida =
                        linhasCobertura[indexLinhasCobertura].indexOf(linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id)

                    if (posTipoCoberturaSeguroVida !== -1) {

                        if (linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].descricao) {

                            descricaoCobertura = linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].descricao

                        } else {

                            descricaoCobertura = linhasCobertura[indexLinhasCobertura].substring(posTipoCoberturaSeguroVida,
                                posTipoCoberturaSeguroVida + linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id.length)
                        }

                        tipoCoberturaSeguroVida = linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].tipoCoberturaSeguroVida

                        dadosCobertura = linhasCobertura[indexLinhasCobertura].substring(posTipoCoberturaSeguroVida +
                            linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id.length + 1).split(espaco)
                    }
                }

                indexPesquisaCobertura++
            }

           if (dadosCobertura) {

                valorCS = trataValorTexto(dadosCobertura[0])
                valorPremio = trataValorTexto(dadosCobertura[1])

                if (coberturas.length === 0) {

                    jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao = trataData(dadosCobertura[3])
                }

                if (dadosCobertura.length === 6) {

                    dataTerminoCobertura = trataData(dadosCobertura[5])

                } else {

                    dataTerminoCobertura = dataAnoMesDia(
                        ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                        mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                        dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                    )
                }

                incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                    HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                        classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
                        
            } else {

                incluiErroTipoCoberturaSeguroVida(linhasCobertura[indexLinhasCobertura],
                    jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)
            }
                
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaApoliceMAGVidaToda = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + espaco + 'Proposta nº:' + espaco, espaco,
        HARDCODE.importacaoNumeroProposta, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta) {
    
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + espaco + 'Proposta nº:' + espaco, fimLinha,
            HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    }

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + espaco + 'Matrícula:' + espaco, espaco,
    //    HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
    jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta
    
    if (PDFTxt.indexOf(' CEP: ') === -1) {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF nº:' + espaco, fimLinha,
            HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF nº:' + espaco, espaco,
            HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CEP:' + espaco, espaco,
            HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'UF:' + espaco, fimLinha,
            HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + espaco + 'Nome do Segurado:' + espaco, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Data de Nascimento:' + espaco, fimLinha,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    if (PDFTxt.indexOf(' CEP: ') !== -1) {
        
        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Endereço:' + espaco, fimLinha,
            HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Bairro:' + espaco, espaco,
            HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Cidade:' + espaco, fimLinha,
            HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)
    }

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da Emissão:' + espaco, espaco, 
    //    HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Controle da Apólice Início / Período de Vigência' + fimLinha,
        fimLinha + espaco + 'Prêmio Líquido (R$)  IOF Total (R$)  Prêmio Total (R$)' + fimLinha, HARDCODE.importacaoCoberturas,
            false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Periodicidade de Pagamento:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Forma de Pagamento:' + espaco, fimLinha,
        HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, HARDCODE.idStatusApoliceAtiva, HARDCODE.importacaoStatusApolice, true, null, erros, nomeArquivo)



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        /*
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'MORTE VIDA INTEIRA' + fimLinha + 'RESGATÁVEL – BENEFÍCIO POR MORTE' + espaco,
                    'MORTE VIDA INTEIRA RESGATÁVEL – BENEFÍCIO POR MORTE' + espaco)
                    
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'MORTE VIDA INTEIRA –' + fimLinha + 'BENEFÍCIO POR MORTE' + espaco,
                    'MORTE VIDA INTEIRA – BENEFÍCIO POR MORTE' + espaco)
                    
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'VIDA INTEIRA –' + fimLinha + 'BENEFÍCIO POR MORTE' + espaco,
                    'VIDA INTEIRA – BENEFÍCIO POR MORTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'BENEFÍCIO POR MORTE' + fimLinha + 'ACIDENTAL' + espaco,
                    'BENEFÍCIO POR MORTE ACIDENTAL' + espaco)
            
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'BENEFICIO POR MORTE' + fimLinha + 'ACIDENTAL' + espaco,
                    'BENEFICIO POR MORTE ACIDENTAL' + espaco)
     
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'PRAZO CERTO –' + fimLinha + 'BENEFÍCIO POR MORTE' + espaco,
                    'PRAZO CERTO – BENEFÍCIO POR MORTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ POR ACIDENTE' + fimLinha + 'VIDA INTEIRA RESGATÁVEL –' + fimLinha + 'INVALIDEZ POR ACIDENTE' + fimLinha,
                    'INVALIDEZ POR ACIDENTE VIDA INTEIRA RESGATÁVEL – INVALIDEZ POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ POR' + fimLinha + 'ACIDENTE VIDA INTEIRA' + fimLinha + 'RESGATÁVEL – INVALIDEZ POR' + fimLinha +
                    'ACIDENTE' + fimLinha,
                        'INVALIDEZ POR ACIDENTE VIDA INTEIRA RESGATÁVEL – INVALIDEZ POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ POR DOENÇA' + fimLinha + 'VIDA INTEIRA RESGATÁVEL –' + fimLinha + 'INVALIDEZ POR DOENÇA' + fimLinha,
                    'INVALIDEZ POR DOENÇA VIDA INTEIRA RESGATÁVEL – INVALIDEZ POR DOENÇA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ POR' + fimLinha + 'ACIDENTE' + espaco,
                    'INVALIDEZ POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ' + fimLinha + 'PERMANENTE TOTAL OU' + fimLinha + 'PARCIAL POR ACIDENTE' + fimLinha,
                    'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ' + fimLinha + 'PERMANENTE POR DOENÇA' + espaco,
                    'INVALIDEZ PERMANENTE POR DOENÇA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DOENÇAS GRAVES' + fimLinha + 'PREMIUM' + espaco,
                    'DOENÇAS GRAVES PREMIUM' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DOENÇAS GRAVES' + fimLinha + 'ESSENCIAL' + espaco,
                    'DOENÇAS GRAVES ESSENCIAL' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'SEGURO DE' + fimLinha + 'ASSISTÊNCIA FUNERAL' + fimLinha + 'FAMILIAR' + fimLinha,
                    'SEGURO DE ASSISTÊNCIA FUNERAL FAMILIAR' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'SEG. ASSIST. FUNERAL' + fimLinha + 'SUPERLUXO FAMILIAR' + espaco,
                    'SEG. ASSIST. FUNERAL SUPERLUXO FAMILIAR' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'SEG. ASSIST. FUNERAL' + fimLinha + 'SUPERLUXO INDIVIDUAL' + espaco,
                    'SEG. ASSIST. FUNERAL SUPERLUXO INDIVIDUAL' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'SEGURO DE' + fimLinha + 'ASSISTÊNCIA FUNERAL' + espaco,
                    'SEGURO DE ASSISTÊNCIA FUNERAL' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIARIA POR' + fimLinha + 'INTERNACAO HOSPITALAR VIDA' + fimLinha + 'INTEIRA RESGATÁVEL – DIÁRIA POR' + fimLinha +
                    'INTERNAÇÃO HOSPITALAR' + fimLinha,
                        'DIARIA POR INTERNACAO HOSPITALAR VIDA INTEIRA RESGATÁVEL – DIÁRIA POR INTERNAÇÃO HOSPITALAR' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR' + fimLinha + 'INTERNAÇÃO HOSPITALAR –' + fimLinha + '150 DIÁRIAS' + fimLinha,
                    'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 150 DIÁRIAS' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR' + fimLinha + 'INTERNAÇÃO HOSPITALAR –' + fimLinha + '200 DIÁRIAS' + fimLinha,
                    'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 200 DIÁRIAS' + espaco)
                    
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR' + fimLinha + 'INTERNAÇÃO HOSPITALAR –' + fimLinha + '250 DIÁRIAS' + fimLinha,
                    'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 250 DIÁRIAS' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INTERNAÇÃO' + fimLinha + 'HOSPITALAR 150 DIARIAS' + espaco,
                    'INTERNAÇÃO HOSPITALAR 150 DIARIAS' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA DE' + fimLinha + 'INCAPACIDADE TEMPORÁRIA' + espaco,
                    'DIÁRIA DE INCAPACIDADE TEMPORÁRIA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR' + fimLinha + 'INCAPACIDADE TEMPORÁRIA' + fimLinha + 'POR ACIDENTE' + fimLinha,
                    'DIÁRIA POR INCAPACIDADE TEMPORÁRIA POR ACIDENTE' + espaco)
        */

        const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
        let indexLinhasCobertura = 0

        const coberturas = []

        let dadosCobertura
        let indexDadosCobertura

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        while (indexLinhasCobertura < linhasCobertura.length) {

            let dadosCoberturaAux = linhasCobertura[indexLinhasCobertura]

            while (dadosCoberturaAux.indexOf(',') === -1) {

                indexLinhasCobertura++

                dadosCoberturaAux += ' ' + linhasCobertura[indexLinhasCobertura]
            }
            
            dadosCobertura = dadosCoberturaAux.split(espaco)

            //dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

            indexDadosCobertura = 0

            descricaoCobertura = ''

            while (indexDadosCobertura < dadosCobertura.length && dadosCobertura[indexDadosCobertura].indexOf(',') === -1) {

                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexDadosCobertura]

                indexDadosCobertura++
            }

            tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            if (tipoCoberturaSeguroVida) {

                const posTraco = descricaoCobertura.indexOf('–')

                codigoCobertura = descricaoCobertura.substring(0, posTraco -1)
                numeroCobertura = dadosCobertura[indexDadosCobertura + 2]

                if (codigoCobertura.length === 3) {

                    codigoCobertura = numeroCobertura.slice(-5).substring(0, 4)
                }

                descricaoCobertura = descricaoCobertura.substring(posTraco + 1)

                if (dadosCobertura.length - 1 > indexDadosCobertura) {

                    valorCS = trataValorTexto(dadosCobertura[indexDadosCobertura + 0])
                    valorPremio = trataValorTexto(dadosCobertura[indexDadosCobertura + 1])

                    if (coberturas.length === 0) {

                        jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao = trataData(dadosCobertura[indexDadosCobertura + 3])
                    }

                    if (dadosCobertura[dadosCobertura.length - 2] === 'ATÉ') {

                        dataTerminoCobertura = trataData(dadosCobertura[indexDadosCobertura + 5])

                    } else {

                        dataTerminoCobertura = dataAnoMesDia(
                            ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                            mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                            dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                        )
                    }

                    incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                        HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                            classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
                } else {

                    incluiErroTipoCoberturaSeguroVida(dadosCoberturaAux,
                        jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)
                }
            }
            
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }
    /*
    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll('RESGATÁVEL –', 'RESGATÁVEL -')

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
        let indexLinhasCoberturaAux = 0

        const linhasCobertura = []
        let indexLinhasCobertura = 0

        const coberturas = []

        let indexPesquisaCobertura
        let posTipoCoberturaSeguroVida

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

            if (
                    linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(' – ') !== -1 &&
                    !isNaN(linhasCoberturaAux[indexLinhasCoberturaAux].substring(0, 1))
            ) {

                linhasCobertura.push(
                    linhasCoberturaAux[indexLinhasCoberturaAux]
                )

            } else {

                linhasCobertura[linhasCobertura.length - 1] += ' ' +  linhasCoberturaAux[indexLinhasCoberturaAux]
            }

            indexLinhasCoberturaAux++
        }

        while (indexLinhasCobertura < linhasCobertura.length) {

            indexPesquisaCobertura = 0

            descricaoCobertura = ''

            dadosCobertura = ''

            while (indexPesquisaCobertura < linhaIntegracao.tipoCoberturaSeguroVida.length) {

                if (!descricaoCobertura) {

                    posTipoCoberturaSeguroVida =
                        linhasCobertura[indexLinhasCobertura].indexOf(linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id)

                    if (posTipoCoberturaSeguroVida !== -1) {

                        descricaoCobertura = linhasCobertura[indexLinhasCobertura].substring(posTipoCoberturaSeguroVida,
                            posTipoCoberturaSeguroVida + linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id.length)

                        tipoCoberturaSeguroVida = linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].tipoCoberturaSeguroVida

                        dadosCobertura = linhasCobertura[indexLinhasCobertura].substring(posTipoCoberturaSeguroVida +
                            linhaIntegracao.tipoCoberturaSeguroVida[indexPesquisaCobertura].id.length + 1).split(espaco)
                    }
                }

                indexPesquisaCobertura++
            }

            valorCS = trataValorTexto(dadosCobertura[0])
            valorPremio = trataValorTexto(dadosCobertura[1])

            if (coberturas.length === 0) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao = trataData(dadosCobertura[3])
            }

            if (dadosCobertura.length === 6) {

                dataTerminoCobertura = trataData(dadosCobertura[5])

            } else {

                const anoEmissao = ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente)
                const mesEmissao = mes(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente)
                const diaEmissao = dia(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente)

                dataTerminoCobertura = dataAnoMesDia(anoEmissao + 100 - ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) - 1,
                    mesEmissao, diaEmissao)
            }

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco,
                    valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
            
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }
    */


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaListaApolicesMAGImplantacao = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    //const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][1] && XLSX[linha][7/*6*/]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            

            const usuarioClienteSelecionado = idUsuarioLogado//idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][38])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice =
                jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16/*15*/, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 26/*22*/, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 50/*44*/, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 48/*42*/, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 51/*45*/, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)
            


            //obtemDadoXLSX(XLSX, jsonRegistros, linha, 47/*41*/, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
            jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente = XLSX[linha][47/*41*/] // tamanho maior que o normal de um endereço

            jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente =
                jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente.replace('CEP:' + espaco, '')

            const endereco = jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente.split('-')

            const tamEndereco = endereco.length

            jsonRegistros.jsonRegistros[indexJsonRegistros].cepSeguradoCliente = endereco[tamEndereco - 2].trim()
            jsonRegistros.jsonRegistros[indexJsonRegistros].ufSeguradoCliente = endereco[tamEndereco - 3].trim()
            jsonRegistros.jsonRegistros[indexJsonRegistros].cidadeSeguradoCliente = endereco[tamEndereco - 4].trim()
            jsonRegistros.jsonRegistros[indexJsonRegistros].bairroSeguradoCliente = endereco[tamEndereco - 5].trim()
            jsonRegistros.jsonRegistros[indexJsonRegistros].complementoEnderecoSeguradoCliente = endereco[tamEndereco - 6].trim()
            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroEnderecoSeguradoCliente = endereco[tamEndereco - 7].trim()

            if (tamEndereco === 10) {
                
                jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente =
                    endereco[0].trim() + espaco + endereco[1].trim() + '-' + endereco[2].trim()

            } else {

                jsonRegistros.jsonRegistros[indexJsonRegistros].enderecoSeguradoCliente =
                    endereco[0].trim() + espaco + endereco[1].trim()
            }



            obtemDadoXLSX(XLSX, jsonRegistros, linha, 45/*39*/, HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 46/*40*/, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 52/*46*/, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)
           
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 30/*26*/, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 31/*27*/, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)


            
            //if (usuarioClienteSelecionado) {
               
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
            
                const coberturas = []

                let valorPremioAnualizado = 0

                while (XLSX[linha][4] === jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice) {

                    valorPremioAnualizado = round(valorPremioAnualizado + XLSX[linha][39/*35*/], 2)

                    let numeroCobertura
                    let codigoCobertura
                    let descricaoCobertura
                    let tipoCoberturaSeguroVida
                    let valorCS
                    let valorPremioLiquido
                    let valorIof
                    let valorPremio
                    let classeAjusteRisco
                    let valorPremioExtra
                    let duracaoPremioExtra
                    let dataTerminoCobertura
                    let valorRendaMensal

                    tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(XLSX[linha][20/*19*/], linhaIntegracao,
                        jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)
        
                    if (tipoCoberturaSeguroVida) {

                        numeroCobertura = XLSX[linha][15/*14*/]
                        codigoCobertura = XLSX[linha][20/*19*/].substring(XLSX[linha][20/*19*/].length - 4)
                        descricaoCobertura = trataTexto(XLSX[linha][20/*19*/].substring(0, XLSX[linha][20/*19*/].length - 6))
                        valorCS = XLSX[linha][24/*20*/]
                        valorPremioLiquido = XLSX[linha][36/*32*/]
                        valorIof = XLSX[linha][37/*33*/]
                        valorPremio = round(valorPremioLiquido + valorIof, 2)

                        if (XLSX[linha][27/*23*/]) {

                            dataTerminoCobertura = trataData(XLSX[linha][27/*23*/])

                        } else {

                            dataTerminoCobertura = dataAnoMesDia(
                                ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                                mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                                dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                            )
                        }

                        const statusCobertura = identificaStatusCobertura(trataTexto(XLSX[linha][28/*24*/]), linhaIntegracao,
                            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                            statusCobertura, valorCS, valorPremioLiquido, valorIof, valorPremio,
                                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
                    }

                    linha++
                }

                jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = valorPremioAnualizado

                jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            //} else {

            //    erros.push(MESSAGE().agenteNaoEncontradoProposta
            //        .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
            //            .replace("$codigoAgente$", XLSX[linha][38])
            //    )

            //    linha++
            //}

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)

        } else {

            linha++
        }
    }

    return jsonRegistros.jsonRegistros
}



export const importaListaComissoesMAG = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {


    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (
                XLSX[linha][0] &&
                XLSX[linha][0] === 'IND'
        ) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][30])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            

            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice =
                jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 31, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoNumeroParcela, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 27, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 19, HARDCODE.importacaoDataGeracaoComissao, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 13, HARDCODE.importacaoValorBaseComissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 14, HARDCODE.importacaoPercComissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 15, HARDCODE.importacaoValorComissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 28, HARDCODE.importacaoPercComissaoCoCorretagem, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 29, HARDCODE.importacaoValorComissaoCoCorretagem, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 34, HARDCODE.importacaoValorComissaoTotal, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9, HARDCODE.importacaoCodigoCobertura, true, null, erros, nomeArquivo)

            if (usuarioClienteSelecionado) {
               
                    jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
                 
            //} else {
        
            //    erros.push(MESSAGE().agenteNaoEncontradoApolice
            //        .replace("$numeroApolice$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice)
            //            .replace("$codigoAgente$", XLSX[linha][30])
            //    )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }
    

    return jsonRegistros.jsonRegistros
}



export const importaCotacaoMAGTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Realizado' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacaoExtenso, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Tempo Idade Prêmio Capital Segurado Reserva' + fimLinha,
        fimLinha + 'COBERTURAS CONTRATADAS' + fimLinha,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = numeroCotacaoAutomatico()
        let dataCotacao = converteDataExtensoData(jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacaoExtenso)
        let ano
        let idade
        let valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].indexOf('R$') !== -1
            ) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            ano = Number.parseInt(dadosTabelaEvolucaoValorResgate[0])

            //if (ano !== 0) {

                idade = Number.parseInt(dadosTabelaEvolucaoValorResgate[1])
                valorCS = trataValorTexto(dadosTabelaEvolucaoValorResgate[5])
                valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[3])
                valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[7])
                valorSaldado = ''
                beneficioProlongado = ''
                
                incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                    valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
            //}

            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}