import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import MESSAGE from '../../../business-const/Message/Message'

import {
    round
} from '../../../business-components/round/round'

import {
    formataDataEmMesAnoSemBarra,
} from '../../../business-components/Date/Date'

import {
    formataValor,
} from '../../../business-components/Formata/Formata'

//import {
//    sortAscId
//} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import titularList from '../../../business-rules/List/titularList'

import tipoRecursoFinanceiroList from '../../../business-rules/List/tipoRecursoFinanceiroList'

import tipoReceitaMensalList from '../../../business-rules/List/tipoReceitaMensalList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import {
    calcDifPerc100,
    calculaValorPerc,
} from '../cliente-form/ClienteService'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    dadosCompletosLinhaReceitaMensal,
} from './GarantiasServiceReceitaMensal'

import {
    consideraRecursoFinanceiro
} from '../estudo-form/EstudoServiceGarantiasRecursosFinanceiros'

import {
    atualizaPerguntaPerc,
} from '../form-form/FormServicePerguntas'



export const incrementaContRecursosFinanceiros = ( values ) => {
    if (!values.contRecursosFinanceiros) {
        values.contRecursosFinanceiros = 0
    }
    values.contRecursosFinanceiros = values.contRecursosFinanceiros + 1
}

export const obtemContRecursosFinanceiros = ( values ) => {

    let contRecursosFinanceiros = ''

    if (!values.contRecursosFinanceiros) {
        incrementaContRecursosFinanceiros(values)
        contRecursosFinanceiros = values.contRecursosFinanceiros

    } else {
        contRecursosFinanceiros = 1

        while (contRecursosFinanceiros <= values.contRecursosFinanceiros) {
            if (!pesquisaList(values.recursosFinanceiros, contRecursosFinanceiros).id) {
                break
            }

            contRecursosFinanceiros++
        }

        if (contRecursosFinanceiros > values.contRecursosFinanceiros) {
            incrementaContRecursosFinanceiros(values)
            contRecursosFinanceiros = values.contRecursosFinanceiros
        }  
    }

    return contRecursosFinanceiros
}

export const incluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT = ( values ) => {
    
    if (values.processarIncluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT) {

        if (values.recursosFinanceiros) {

            let index = values.recursosFinanceiros.length - 1

            while (index >= 0) {

                if (values.recursosFinanceiros[index].idReceitaMensal) {
                    values.recursosFinanceiros.splice(index, 1)
                }

                index -= 1
            }
        }

        values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

            let linhaTipoReceitaMensal = pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal)

            if (
                    dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                    linhaTipoReceitaMensal.calculaFGTSContribuicao === "S"
            ) {

                if (!values.recursosFinanceiros) {
                    values.recursosFinanceiros = []
                }

                values.recursosFinanceiros.push(
                    {
                        id: obtemContRecursosFinanceiros(values),
                        familiar: linhaReceitaMensal.familiar,
                        tipoRecursoFinanceiro: HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao,
                        valor: round(linhaReceitaMensal.valorBruto * HARDCODE.percFgts / 100, 2),
                        observacao: MESSAGE().fgtsContribuicaoIncluidoReceitaMensal +
                            (linhaReceitaMensal.observacao ? " (" + linhaReceitaMensal.observacao + ")" : '') +
                                (linhaReceitaMensal.tipoPeriodicidadeRecebto === HARDCODE.idTipoPeriodicidadeMensal
                                    ?
                                        ''
                                    :
                                        ' (' + pesquisaDescricaoList(tipoPeriodicidadeList(false, false),
                                            linhaReceitaMensal.tipoPeriodicidadeRecebto) + ')'
                                ),
                        idReceitaMensal: linhaReceitaMensal.id,
                    }
                )
            }
        })

        //if (values.recursosFinanceiros) { // Estava causando problema de desposicionamento na edição dos Recursos Financeiro no Modo Apresentação 
        //    values.recursosFinanceiros = sortAscId(values.recursosFinanceiros)
        //}

        calculaTotaisRecursosFinanceiros(values)

        values.processarIncluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT = false
    }
}



export const processaRegistrosDefaultIniciaisRecursosFinanceiros = ( values, familiar ) => {

    if (familiar) {
        if (!values.familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros) {
            values.familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros = []
        }

        if (!values.recursosFinanceiros) {
            values.recursosFinanceiros = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros.push(
                {
                    id: familiar
                }
            )

            incrementaContRecursosFinanceiros(values)
            values.recursosFinanceiros.push(
                {
                    id: values.contRecursosFinanceiros,
                    familiar: familiar,
                }
            )
        }
    }
}

export const atualizaFormListRecursosFinanceiros = ( values, idPergunta, valor, perc, idRecursoFinanceiro ) => {

    if (valor > 0) {

        if (!idRecursoFinanceiro) {

            if (!values.recursosFinanceiros) {
                values.recursosFinanceiros = []
            }

            incrementaContRecursosFinanceiros(values)
            values.recursosFinanceiros.push(
                {
                    id: values.contRecursosFinanceiros,
                    familiar: perc || perc === 0 ? HARDCODE.idFamiliarCasal : HARDCODE.idFamiliarCliente,
                    tipoRecursoFinanceiro: HARDCODE.idTipoRecursoFinanceiroOutros,
                    valor: valor,
                    percRecursosFinanceirosCliente: perc || perc === 0 ? perc : '',
                    percRecursosFinanceirosConjuge: perc || perc === 0 ? calcDifPerc100(perc) : '',
                    observacao: MESSAGE().incluidoPerguntas,
                    idPergunta: idPergunta,
                }
            )

            idRecursoFinanceiro = values.contRecursosFinanceiros

        } else {

            values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

                if (linhaRecursosFinanceiros.idPergunta === idPergunta) {
    
                    values.recursosFinanceiros[index].familiar =
                        perc || perc === 0 ? HARDCODE.idFamiliarCasal : HARDCODE.idFamiliarCliente
                    values.recursosFinanceiros[index].valor =
                        valor
                    values.recursosFinanceiros[index].percRecursosFinanceirosCliente =
                        perc || perc === 0 ? perc : ''
                    values.recursosFinanceiros[index].percRecursosFinanceirosConjuge =
                        perc || perc === 0 ? calcDifPerc100(perc) : ''
                }
            })
        }

        calculaTotaisRecursosFinanceiros(values)

    } else {

        if (idRecursoFinanceiro) {

            let index = values.recursosFinanceiros.length - 1

            while (index >= 0) {

                if (values.recursosFinanceiros[index].idPergunta === idPergunta) {
                    values.recursosFinanceiros.splice(index, 1)
                }

                index -= 1
            }

            idRecursoFinanceiro = null

            calculaTotaisRecursosFinanceiros(values)
        }
    }

    return idRecursoFinanceiro
}



export const processaValoresDefaultIniciaisRecursosFinanceiros = ( item, values ) => {

    if (
            item.familiar === HARDCODE.idFamiliarCasal &&
            !item.percRecursosFinanceirosCliente &&
            !item.percRecursosFinanceirosCliente !== 0
    ) {

        item.percRecursosFinanceirosCliente =
            values.percMeacaoCliente
                ?
                    values.percMeacaoCliente
                :
                    HARDCODE.percRecursosFinanceirosClienteDefault

    }

}


const calculaRecursosFinanceirosTipoPeriodicidadeAporteDividendos = ( item ) => {

    if (!item.tipoPeriodicidadeAporteDividendos && (item.valorAporteDividendos || item.valorAporteEmpresa)) {

        item.tipoPeriodicidadeAporteDividendos = pesquisaList(tipoRecursoFinanceiroList,
            item.tipoRecursoFinanceiro).tipoPeriodicidadeAporteDividendosDefault
    }
}

const calculaRecursosFinanceirosMesAnoInicialFinalAporteDividendos = ( values, item ) => {

    if (
            !item.mesAnoInicialAporteDividendos &&
            !item.mesAnoFinalAporteDividendos && 
            (
                item.valorAporteDividendos ||
                item.valorAporteEmpresa
            )
    ) {

        if (!item.aporteDividendosAteAposentadoria) {

            item.mesAnoInicialAporteDividendos = formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)

            item.mesAnoFinalAporteDividendos = '12'

            if (item.familiar === HARDCODE.idFamiliarCasal || item.familiar === HARDCODE.idFamiliarCliente) {

                item.mesAnoFinalAporteDividendos += values.anoFinalVaiAposentarCliente
            }

            if (item.familiar === HARDCODE.idFamiliarConjuge) {

                item.mesAnoFinalAporteDividendos += values.anoFinalVaiAposentarConjuge
            }
        }
    }
}

const calculaRecursosFinanceirosZeraCamposNaoInformados = ( item/*, jsonConfiguracao*/ ) => {

    const tipoRecursoFinanceiro = pesquisaList(tipoRecursoFinanceiroList, item.tipoRecursoFinanceiro)

    if (tipoRecursoFinanceiro.informaAporteDividendos !== "S") {

        item.valorAporteDividendos = ''
    }

    if (tipoRecursoFinanceiro.informaAporteEmpresa !== "S") {

        item.valorAporteEmpresa = ''
    }

    if (!item.valorAporteDividendos && !item.valorAporteEmpresa) {

        item.tipoPeriodicidadeAporteDividendos = ''
        item.mesAnoInicialAporteDividendos = ''
        item.mesAnoFinalAporteDividendos = ''
        item.aporteDividendosAteAposentadoria = false
        item.aporteOriundoReceitaMensal = false
        item.aporteOriundoRealocacaoCarteiraInvestimentos = false
        item.controlarAportes = false
    }

    if (item.aporteDividendosAteAposentadoria) {

        item.mesAnoFinalAporteDividendos = ''
    }

    if (tipoRecursoFinanceiro.informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos !== "S") {

        item.aporteOriundoReceitaMensal = false
        item.aporteOriundoRealocacaoCarteiraInvestimentos = false
    }

    if (tipoRecursoFinanceiro.informaPercRentabilidadeRecursoFinanceiro !== "S") {

        item.percRentabilidadeAnualRecursoFinanceiro = ''
    }

    if (!item.percRentabilidadeAnualRecursoFinanceiro) {

        item.tipoMomentoTributacaoIR = ''
        item.percIR = ''
        item.tempoMinimoEntreAportesResgatesCalculoIRAnos = ''
    }

    if (!(item.percRentabilidadeAnualRecursoFinanceiro || item.percRentabilidadeAnualRecursoFinanceiro === 0)) {
        
        item.taxaInflacaoAnualRecursoFinanceiro = ''
    }
    
    //if (tipoRecursoFinanceiro.informaTaxaAnual !== "S") {

    //    item.taxaAnual = ''
    //}
       
    if (tipoRecursoFinanceiro.informaVencimento !== "S") {

        item.vencimento = null
    }
           
    if (tipoRecursoFinanceiro.informaInstituicao !== "S") {
        item.instituicao = ''
    }
               
    if (tipoRecursoFinanceiro.informaNomeAcao !== "S") {

        item.nomeAcao = ''
    }

    if (tipoRecursoFinanceiro.informaRegimeTributacao !== "S") {

        item.tipoRegimeTributacao = ''
    }
    
    if (tipoRecursoFinanceiro.informaNomeFundo !== "S") {

        item.nomeFundo = ''
    }

    if (tipoRecursoFinanceiro.informaCnpjFundo !== "S") {

        item.cnpjFundo = ''
    }

    if (item.familiar !== HARDCODE.idFamiliarCasal) {

        item.percRecursosFinanceirosCliente = ''
        item.percRecursosFinanceirosConjuge = ''
    }

    if (
            tipoRecursoFinanceiro.inventario === "N"/* ||
            (
                tipoRecursoFinanceiro.inventario ===
                    HARDCODE.inventarioPrevidenciaPrivada &&
                (
                    !jsonConfiguracao ||
                    (
                        jsonConfiguracao &&
                        !jsonConfiguracao.considerarPrevidenciaPrivadaInventario
                    )
                )
            )*/
    ) {

        item.naoInventariar = false
    }
}

export const processaAlteracaoRecursosFinanceirosControlarAportes = ( item ) => {

    if (!item.controlarAportes) {

        item.diaAporte = ''
    }
}

export const processaAlteracaoRecursosFinanceirosTipoRecursoFinanceiro = ( values, item/*, jsonConfiguracao*/ ) => {

    calculaRecursosFinanceirosTipoPeriodicidadeAporteDividendos(item)
    calculaRecursosFinanceirosMesAnoInicialFinalAporteDividendos(values, item)
    calculaRecursosFinanceirosZeraCamposNaoInformados(item/*, jsonConfiguracao*/)
    processaAlteracaoRecursosFinanceirosControlarAportes(item)
}



const calculaRecursosFinanceirosPercRecursosFinanceirosConjuge = ( item ) => {

    if (item.percRecursosFinanceirosCliente === 0 || item.percRecursosFinanceirosCliente) {

        item.percRecursosFinanceirosConjuge = calcDifPerc100(item.percRecursosFinanceirosCliente)

    } else {

        item.percRecursosFinanceirosConjuge = ''
    }
}



export const processaAlteracaoRecursosFinanceirosPercRecursosFinanceirosCliente = ( values, item ) => {

    calculaRecursosFinanceirosPercRecursosFinanceirosConjuge(item)

    atualizaPerguntaPerc(values, item.idPergunta, item.percRecursosFinanceirosCliente)
}



export const dadosCompletosLinhaRecursosFinanceiros = ( linhaRecursosFinanceiros ) => {
    
    return  linhaRecursosFinanceiros.valor && 
            (
                pesquisaList(tipoRecursoFinanceiroList, 
                    linhaRecursosFinanceiros.tipoRecursoFinanceiro).informaAporteDividendos !== "S" ||
                (
                    (
                        (
                            linhaRecursosFinanceiros.valorAporteDividendos ||
                            linhaRecursosFinanceiros.valorAporteEmpresa
                        ) &&
                        linhaRecursosFinanceiros.mesAnoInicialAporteDividendos &&
                        (
                            linhaRecursosFinanceiros.mesAnoFinalAporteDividendos ||
                            linhaRecursosFinanceiros.aporteDividendosAteAposentadoria
                        )
                    ) ||
                    (
                        !linhaRecursosFinanceiros.valorAporteDividendos &&
                        !linhaRecursosFinanceiros.valorAporteEmpresa
                    )
                )
            ) && 
            (
                !linhaRecursosFinanceiros.aporteOriundoRealocacaoCarteiraInvestimentos ||
                linhaRecursosFinanceiros.idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos
            ) &&
            (
                linhaRecursosFinanceiros.familiar !== HARDCODE.idFamiliarCasal || 
                linhaRecursosFinanceiros.percRecursosFinanceirosCliente || 
                linhaRecursosFinanceiros.percRecursosFinanceirosCliente === 0
            )
}

export const calculaTotaisRecursosFinanceiros = ( values ) => {

    const indexStepsValorPatrimonio = HARDCODE.indexPatrimonioRecursosFinanceiros

    values.stepsValorPatrimonio[indexStepsValorPatrimonio] = 0

    values.totalRecursosFinanceiros = []

    let valorTotal = 0

    titularList(values, 'completo')?.forEach( (linhaTitularList, index) => {
        valorTotal = 0

        values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {
            if (
                    (
                        linhaRecursosFinanceiros.familiar === linhaTitularList.id ||
                        (
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal &&
                            (
                                linhaTitularList.id === HARDCODE.idFamiliarCliente ||
                                linhaTitularList.id === HARDCODE.idFamiliarConjuge
                            )
                        )
                    ) &&
                    dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros) &&
                    linhaTitularList.id !== HARDCODE.idFamiliarCasal &&
                    pesquisaList(tipoRecursoFinanceiroList,
                        linhaRecursosFinanceiros.tipoRecursoFinanceiro).totalizaEntradaDados === "S"
            ) {

                if (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal) {

                    if (linhaTitularList.id === HARDCODE.idFamiliarCliente) {

                        valorTotal = valorTotal +
                            calculaValorPerc(round(linhaRecursosFinanceiros.valor, 2),
                                linhaRecursosFinanceiros.percRecursosFinanceirosCliente)

                    } else {

                        if (linhaTitularList.id === HARDCODE.idFamiliarConjuge) {

                            valorTotal = valorTotal + round(linhaRecursosFinanceiros.valor, 2) -
                                calculaValorPerc(round(linhaRecursosFinanceiros.valor, 2),
                                    linhaRecursosFinanceiros.percRecursosFinanceirosCliente)
                        }
                    }

                } else {

                    valorTotal = valorTotal + round(linhaRecursosFinanceiros.valor, 2)
                }
            }
        })

        if (
                valorTotal !== 0 ||
                linhaTitularList.id === HARDCODE.idFamiliarCasal ||
                linhaTitularList.id === HARDCODE.idFamiliarCliente ||
                linhaTitularList.id === HARDCODE.idFamiliarConjuge
        ) {

            values.totalRecursosFinanceiros.push(
                {
                    id: linhaTitularList.id,
                    descricao: linhaTitularList.descricao,
                    valor: valorTotal,
                }
            )
            
            values.stepsValorPatrimonio[indexStepsValorPatrimonio] += valorTotal
        }
    })
}



export const dadosCompletosRecursosFinanceiros = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    titularList(values, 'completo')?.forEach( (linhaTitularList, index) => {
        
        values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

            if (
                    linhaRecursosFinanceiros.familiar === linhaTitularList.id &&
                    linhaRecursosFinanceiros.tipoRecursoFinanceiro &&
                    !dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaTitularList,
                    dadosIncompletos, pesquisaDescricaoList(tipoRecursoFinanceiroList,
                        linhaRecursosFinanceiros.tipoRecursoFinanceiro))
            }
        })
    })

    return dadosIncompletos
}



export const possuiFGTS = ( values ) => {

    let possuiFGTSAux = false

    values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

        if (consideraRecursoFinanceiro(values, linhaRecursosFinanceiros)) {
            
            if (
                    linhaRecursosFinanceiros.tipoRecursoFinanceiro ===
                        HARDCODE.idTipoRecursoFinanceiroFgtsSaldo ||
                    linhaRecursosFinanceiros.tipoRecursoFinanceiro ===
                        HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao
            ) {
                
                possuiFGTSAux = true
            }
        }
    })

    return possuiFGTSAux
}



export const tipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentosList = (values, item) => {

    const tipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentosListAux = []

    values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, itemRecursosFinanceiros) => {

        const linhaTipoRecursoFinanceiroList = pesquisaList(tipoRecursoFinanceiroList, linhaRecursosFinanceiros.tipoRecursoFinanceiro)

        if (linhaRecursosFinanceiros.id !== item.id/* && linhaTipoRecursoFinanceiroList.totalizaEntradaDados === "S"*/) {

            const descricao = linhaTipoRecursoFinanceiroList.descricao + ' - ' +
                (linhaRecursosFinanceiros.descricaoOutros ? linhaRecursosFinanceiros.descricaoOutros + ' - ' : '') + 
                    formataValor(round(linhaRecursosFinanceiros.valor, 2), 2)

            tipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentosListAux.push(
                {
                    id: linhaRecursosFinanceiros.id,
                    descricao: descricao,
                    ordem: descricao,
                }
            )
        }
    })

    return tipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentosListAux
}



export const origemRealocacaoCarteiraInvestimentos = (values, item) => {

    let origem = false

    if (values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira && item.id === values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira) {

        origem = true

    } else {

        values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, itemRecursosFinanceiros) => {

            if (item.id === linhaRecursosFinanceiros.idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos) {

                origem = true
            }
        })
    }

    return origem
}



export const calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro = ( values, idFamiliar, tipoRecursoFinanceiro ) => {

    let valor = 0

    values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {
            
        if (
            linhaRecursosFinanceiros.familiar === idFamiliar &&
                dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros) &&
                linhaRecursosFinanceiros.tipoRecursoFinanceiro === tipoRecursoFinanceiro &&
                !pesquisaList(values.exclusaoFamiliares, linhaRecursosFinanceiros.familiar).id &&
                !pesquisaList(values.exclusaoDespesasGarantias,
                    LABEL.recursosFinanceiros + '/' + linhaRecursosFinanceiros.id).id
        ) {

            valor += round(linhaRecursosFinanceiros.valor, 2)
        }
    })

    return valor
}