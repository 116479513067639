import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    ano,
    mes,
    dia,
    dataAnoMesDia,
} from '../../../business-components/Date/Date'

//import {
//    round
//} from '../../../business-components/round/round'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import {
    trataTexto,
    trataValorTexto,
    trataPercentualTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    identificaTipoCoberturaSeguroVida,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    incluiPosicaoInvestimentos,
} from './IntegracaoClienteArquivoService'



export const importaPropostaXP = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    
    
    //PDFTxt = PDFTxt.replace(fimLinha + 'Complemento:' + fimLinha + 'Bairro:' + fimLinha, 
    //    fimLinha + 'Complemento:' + fimLinha + '' + fimLinha + 'Bairro:' + fimLinha)



    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Data da Proposta' + espaco, fimLinha,
        HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº Proposta' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome Completo' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'E-mail' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF' + fimLinha, fimLinha, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento' + fimLinha, fimLinha, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone Celular' + fimLinha, espaco + 'Masculino',
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)
        
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo:' + fimLinha, fimLinha,
    //    HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade de Pagamento' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Prazo' + fimLinha + 'Vigência' + fimLinha + 'Prazo' + fimLinha + 'Pagamento',
        fimLinha + 'Prêmio anual total' + fimLinha, HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
        let indexLinhasCobertura = -1


        
        const coberturas = []

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra = ''
        let duracaoPremioExtra = ''
        let dataTerminoCobertura
        let valorRendaMensal = ''

        indexLinhasCobertura = 0

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
            
            valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 4])
            valorPremioLiquido = ''
            valorIof = ''
            valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 6])
            classeAjusteRisco = trataTexto(dadosCobertura[dadosCobertura.length - 8])

            if (dadosCobertura[dadosCobertura.length - 3] === 'VITALÍCIO') {

                dataTerminoCobertura = dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura)
                )

            } else {

                dataTerminoCobertura = trataData(dadosCobertura[dadosCobertura.length - 3])
            } 

            descricaoCobertura = ''

            let indexLinhasCoberturaAux = 0

            while (indexLinhasCoberturaAux < dadosCobertura.length - 8) {
            
                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
            
                indexLinhasCoberturaAux++
            }

            const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida, null,
                valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
                    dataTerminoCobertura, valorRendaMensal)

            indexLinhasCobertura += 1
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaPosicaoInvestimentosXP = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replaceAll('\u0000', 'fi')



    jsonRegistros.jsonRegistros[indexJsonRegistros].instituicaoFinanceira = HARDCODE.idInstituicaoFinanceiraXP

    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Conta Parceiro Data de Referência' + fimLinha, espaco,
        HARDCODE.importacaoNumeroConta, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].numeroConta) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Conta Assessor Data de Referência' + fimLinha, espaco,
            HARDCODE.importacaoNumeroConta, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].numeroConta) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Conta Banker Data de Referência' + fimLinha, espaco,
                HARDCODE.importacaoNumeroConta, true, null, erros, nomeArquivo)
        }
    }


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Í N D I C E S  D E  R E F E R Ê N C I A' + fimLinha +
        'Benchmarks Mês Atual Ano 12M 24M' + fimLinha + 'CDI' + espaco, fimLinha,
            HARDCODE.importacaoTaxaCDI12Meses, true, null, erros, nomeArquivo)
    
    jsonRegistros.jsonRegistros[indexJsonRegistros].taxaCDI12Meses =
        trataPercentualTexto(jsonRegistros.jsonRegistros[indexJsonRegistros].taxaCDI12Meses.split(espaco)[2])
    
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'P O S I Ç Ã O  D E T A L H A D A  D O S  AT I V O S' + fimLinha,
        fimLinha + 'M O V I M E N T A Ç Õ E S' + fimLinha +
            'M O V I M E N T A Ç Õ E S  D A  C O N T A  ( Ú LT I M O S  3 O  D I A S )' + fimLinha,
                HARDCODE.importacaoPosicaoInvestimentos, false, null, erros, nomeArquivo)
                
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].posicaoInvestimentos) {
    
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'P O S I Ç Ã O  D E T A L H A D A  D O S  AT I V O S' + fimLinha,
            fimLinha + 'Este material foi elaborado pela XP INVESTIMENTOS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A., tem caráter meramente informativo, não constitui e nem deve ser' +
                fimLinha, HARDCODE.importacaoPosicaoInvestimentos, true, null, erros, nomeArquivo)
    }

    const linhasPosicaoInvestimentos = jsonRegistros.jsonRegistros[indexJsonRegistros].posicaoInvestimentos.split(fimLinha)
    let indexLinhasPosicaoInvestimentos = linhasPosicaoInvestimentos.length - 1

    const posicaoInvestimentos = []

    let posFinalDescricao
    let descricao
    let tipoRecursoFinanceiro
    let valor
    let percRentabilidadeAnualRecursoFinanceiro

    let dadosPosicaoInvestimentos

    indexLinhasPosicaoInvestimentos = 0

    while (indexLinhasPosicaoInvestimentos < linhasPosicaoInvestimentos.length) {

        if (linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos].substring(0, 2) === 'R$') {

            linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos] =
                linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos - 2] + espaco +
                    linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos - 1] + espaco +
                        linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos]
        }

        indexLinhasPosicaoInvestimentos++
    } 
            
    indexLinhasPosicaoInvestimentos = linhasPosicaoInvestimentos.length - 1

    while (indexLinhasPosicaoInvestimentos >= 0) {

        if (linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos].indexOf('R$') === -1) {

            linhasPosicaoInvestimentos.splice(indexLinhasPosicaoInvestimentos, 1)
        }

        indexLinhasPosicaoInvestimentos -= 1
    }

    indexLinhasPosicaoInvestimentos = 0

    while (indexLinhasPosicaoInvestimentos < linhasPosicaoInvestimentos.length) {

        posFinalDescricao = linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos].indexOf('R$') - 1
        descricao = linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos].substring(0, posFinalDescricao)

        const tipoRecursoFinanceiroAux = pesquisaList(linhaIntegracao.tipoRecursoFinanceiro, descricao).idConversao

        if (tipoRecursoFinanceiroAux) {

            tipoRecursoFinanceiro = tipoRecursoFinanceiroAux

        } else {

            dadosPosicaoInvestimentos =
                linhasPosicaoInvestimentos[indexLinhasPosicaoInvestimentos].substring(posFinalDescricao + 1).split(espaco)

            valor = trataValorTexto(dadosPosicaoInvestimentos[0])
            //percRentabilidadeAnualRecursoFinanceiro =
            //    round(jsonRegistros.jsonRegistros[indexJsonRegistros].taxaCDI12Meses *
            //        (trataPercentualTexto(dadosPosicaoInvestimentos[10]) / 100), 2)

            incluiPosicaoInvestimentos(posicaoInvestimentos, tipoRecursoFinanceiro, descricao, valor,
                percRentabilidadeAnualRecursoFinanceiro)
        }

        indexLinhasPosicaoInvestimentos++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].posicaoInvestimentos = posicaoInvestimentos



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}