import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    round
} from '../../../business-components/round/round'

import {
    ano,
    mes,
} from '../../../business-components/Date/Date'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTituloDestaque2Titulos,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosValorDestaque,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList
 } from '../../../business-rules/List/ListService'

import tipoDiaDiaList from '../../../business-rules/List/tipoDiaDiaList'

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    dadosCompletosConjuge,
    calculaValorMensal,
    converteMesesTipoPeriodicidade,
    calculaIntervaloTempoAnosMorteAteVitalicio,
} from '../cliente-form/ClienteService'

import {
    possuiFamiliares,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosLinhaDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    calculaDescricao,
    calculaDescricaoGrupoDespesa,
    formataValorTipoPeriodicidade,
} from './EstudoService'

import {
    inicializaSerieGraficoDespesa,
    inicializaSerieGrafico,
    incluiDespesaMensalDetalhadaTemp,
    calculaGraficoValorPresente,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'



export const calculaDespesasDiaDiaDespesasVitalicias = (values, estudo, report) => {
    let diaDiaDespesasVitaliciasTemp = []
    let diaDiaDespesasVitalicias = []
    let panelDespesasTotaisDiaDiaDespesasVitalicias

    if (values.diaDia) {
        let linhaTipoDiaDiaDespesasVitalicias
        let nomeCurtoFamiliar
        let descricaoDiaDiaDespesasVitalicias

        let indexGraficoConsumoReservaAposentadoria
        let indexGraficoDespesaAnual
        let indexGraficoDespesaAnualMedia

        let anoInicial = ano(values.dataSimulacaoEstudo)
        let mesAnoInicial = mes(values.dataSimulacaoEstudo)
        let anoFinal
        let anoFinalClienteConjuge
        let anoFinalVaiAposentar
        
        let valor /**/
        let valorMensal
        let mesesValorMensal
        let valorNaAposentadoria /**/
        let valorNaAposentadoriaMensal
        let mesesValorNaAposentadoriaMensal
        let valorAcumulado
        let valorResponsabilidadeCliente
        let valorResponsabilidadeConjuge

        let valorMensalTotal = 0
        let valorNaAposentadoriaMensalTotal = 0
        let valorAcumuladoTotal = 0
        let valorResponsabilidadeClienteTotal = 0
        let valorResponsabilidadeConjugeTotal = 0

        let saldo

        values.diaDia.forEach( (linhaDiaDiaDespesasVitalicias, index) => {

            linhaTipoDiaDiaDespesasVitalicias = pesquisaList(tipoDiaDiaList, linhaDiaDiaDespesasVitalicias.tipoDiaDia)

            if (
                    dadosCompletosLinhaDiaDia(linhaDiaDiaDespesasVitalicias) &&
                    !linhaDiaDiaDespesasVitalicias.alterarPeriodoDiaDia &&
                    !pesquisaList(values.exclusaoFamiliares, linhaDiaDiaDespesasVitalicias.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.diaDia + '/' + linhaDiaDiaDespesasVitalicias.id).id &&
                    (
                        (
                            values.morteInvalidezEstudo === "I" &&
                            linhaTipoDiaDiaDespesasVitalicias.morte === "N"
                        ) ||
                        (
                            values.morteInvalidezEstudo !== "I" && 
                            linhaTipoDiaDiaDespesasVitalicias.invalidez === "N"
                        )
                    ) &&
                    (
                        (
                            (
                                values.morteInvalidezEstudo === "A" ||
                                values.morteInvalidezEstudo === "M"
                            ) &&
                            linhaTipoDiaDiaDespesasVitalicias.invalidez === "N"
                        ) ||
                        (
                            values.morteInvalidezEstudo !== "A" && 
                            values.morteInvalidezEstudo !== "M" && 
                            linhaTipoDiaDiaDespesasVitalicias.morte === "N"
                        )
                    )
            ) {

                nomeCurtoFamiliar = pesquisaDescricaoList(familiaresList(values), linhaDiaDiaDespesasVitalicias.familiar)

                descricaoDiaDiaDespesasVitalicias =
                    calculaDescricao(linhaTipoDiaDiaDespesasVitalicias.descricao, linhaDiaDiaDespesasVitalicias.descricaoDiversasOutros)

                if (linhaDiaDiaDespesasVitalicias.despesaPessoal) {

                    anoFinal =
                        linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente
                            ?
                                values.anoFinalVitalicioCliente
                            :
                                values.anoFinalVitalicioConjuge

                    anoFinalClienteConjuge =
                        linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente
                            ?
                                values.anoFinalCliente
                            :
                                values.anoFinalConjuge

                } else {

                    anoFinal = values.anoFinalVitalicioClienteConjuge
                    anoFinalClienteConjuge = values.anoFinalClienteConjuge
                }

                valor = round(linhaDiaDiaDespesasVitalicias.valor, 2) /**/
                valorMensal = calculaValorMensal (linhaDiaDiaDespesasVitalicias.valor,
                    linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto)

                anoFinalVaiAposentar =
                    linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente
                        ?
                            values.anoFinalVaiAposentarCliente
                        :
                            values.anoFinalVaiAposentarConjuge

                mesesValorMensal = ((/*anoFinalVaiAposentar*/values.anoFinalVaiAposentarCliente - anoInicial) * 12) + 12 - mesAnoInicial + 1
                mesesValorNaAposentadoriaMensal = (anoFinalClienteConjuge - /*anoFinalVaiAposentar*/values.anoFinalVaiAposentarCliente) * 12

                if (linhaTipoDiaDiaDespesasVitalicias.morte === "N" && linhaTipoDiaDiaDespesasVitalicias.invalidez === "N") {

                    if (linhaDiaDiaDespesasVitalicias.consumirRecursosFinanceirosEmVezDaReceitaMensal) {

                        indexGraficoConsumoReservaAposentadoria =
                            inicializaSerieGrafico(values, estudo.current.graficoConsumoReservaAposentadoria, linhaTipoDiaDiaDespesasVitalicias,
                                LABEL.diaDia + LABEL.traco + descricaoDiaDiaDespesasVitalicias,
                                    LABEL.diaDia + LABEL.traco + descricaoDiaDiaDespesasVitalicias)

                    } else {

                        indexGraficoDespesaAnual =
                            inicializaSerieGrafico(values, estudo.current.graficoDespesaAnual,
                                inicializaSerieGraficoDespesa(linhaDiaDiaDespesasVitalicias,
                                    linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesaOutras,
                                        linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesaOutras), null, null)
                            
                        /**/
                        indexGraficoDespesaAnualMedia =
                            inicializaSerieGrafico(values, estudo.current.graficoDespesaAnualMedia,
                                inicializaSerieGraficoDespesa(linhaDiaDiaDespesasVitalicias,
                                    linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesaOutras,
                                        linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesaOutras), null, null)
                        /**/
                    }
                }

                if (linhaDiaDiaDespesasVitalicias.valorNaAposentadoria) {

                    valorNaAposentadoria = round(linhaDiaDiaDespesasVitalicias.valorNaAposentadoria, 2)/**/
                    valorNaAposentadoriaMensal = 
                        calculaValorMensal (linhaDiaDiaDespesasVitalicias.valorNaAposentadoria,
                            linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto)

                    if (
                            linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente ||
                            linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarConjuge
                    ) {

                        valorAcumulado = estudo.current.graficoDiaDiaDespesasVitalicias[0]

                        calculaGraficoValorPresente(null, anoInicial, null,
                            anoFinalClienteConjuge < anoFinalVaiAposentar ? anoFinalClienteConjuge :
                                anoFinalVaiAposentar, valor/*valorMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                    estudo.current.graficoAnos, estudo.current.graficoDiaDiaDespesasVitalicias,
                                        estudo.current.graficoDespesasAcumuladas, values, estudo,
                                            values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                                values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                                    values.taxaRetornoAnualRealAposAposentadoria, null, null, true
                        )
        
                    if (linhaTipoDiaDiaDespesasVitalicias.morte === "N" && linhaTipoDiaDiaDespesasVitalicias.invalidez === "N") {

                            if (linhaDiaDiaDespesasVitalicias.consumirRecursosFinanceirosEmVezDaReceitaMensal) {

                                saldo = calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalVaiAposentar, 0,
                                    /*valorMensal*/valor * -1, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null,
                                                            estudo.current.evolucaoReservaFinanceiraEstudo)
        
                                calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null,
                                    values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                        estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                values, estudo, values.taxaRetornoAnualReal,
                                                    values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null)

                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalVaiAposentar, 0,
                                    valor/*valorMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                                null, values, estudo, 0, 0, false, null, null, null)

                            } else {

                                //calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalVaiAposentar, 0,
                                //    valorMensal, null, estudo.current.graficoAnos,
                                //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null)

                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalVaiAposentar, 0,
                                    valor/*valorMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                                estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                                    null, null, null)

                                if (valor > 0) {
                                
                                    incluiDespesaMensalDetalhadaTemp(values, estudo, linhaDiaDiaDespesasVitalicias, descricaoDiaDiaDespesasVitalicias,
                                        valor, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, valorMensal, null,
                                            mesAnoInicial, anoInicial, 12, anoFinalVaiAposentar)
                                }

                                /**/
                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalVaiAposentar, 0,
                                    valorMensal, null, estudo.current.graficoAnos,
                                        estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                            estudo.current.graficoDespesaAnualTotalMedia,
                                                values, estudo, 0, 0, false, null, null, null)
                                /**/
                            }
                        }

                        if (anoFinalClienteConjuge > anoFinalVaiAposentar) {

                            calculaGraficoValorPresente(null, anoFinalVaiAposentar + 1, null,
                                anoFinalClienteConjuge, valorNaAposentadoria/*valorNaAposentadoriaMensal*/,
                                    linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/, estudo.current.graficoAnos,
                                        estudo.current.graficoDiaDiaDespesasVitalicias,
                                            estudo.current.graficoDespesasAcumuladas, values, estudo,
                                                values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                                    values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                                        values.taxaRetornoAnualRealAposAposentadoria, null, null, true
                            )
                        }
        
                        if (linhaTipoDiaDiaDespesasVitalicias.morte === "N" && linhaTipoDiaDiaDespesasVitalicias.invalidez === "N") {

                            if (linhaDiaDiaDespesasVitalicias.consumirRecursosFinanceirosEmVezDaReceitaMensal) {

                                saldo = calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null, anoFinal,
                                    0, /*valorNaAposentadoriaMensal*/valorNaAposentadoria * -1, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                values, estudo, values.taxaRetornoAnualReal,
                                                    values.taxaRetornoAnualRealAposAposentadoria, true, null, null,
                                                        estudo.current.evolucaoReservaFinanceiraEstudo)

                                if (anoFinal < values.anoFinalVitalicioClienteConjuge) {

                                    calculaGraficoAcumulacaoMensal(null, anoFinal + 1, null,
                                        values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null)
                                }

                                calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null, anoFinal, 0,
                                    valorNaAposentadoria/*valorNaAposentadoriaMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                                null, values, estudo, 0, 0, false, null, null, null)
        
                            } else {

                                //calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null, anoFinal, 0,
                                //    valorNaAposentadoria/*valorNaAposentadoriaMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                //        estudo.current.graficoAnos,
                                //            estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null)

                                calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null, anoFinal, 0,
                                    valorNaAposentadoria/*valorNaAposentadoriaMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos, estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                            estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                                null, null, null)

                                if (valorNaAposentadoria > 0) {
                                
                                    incluiDespesaMensalDetalhadaTemp(values, estudo, linhaDiaDiaDespesasVitalicias, descricaoDiaDiaDespesasVitalicias,
                                        valorNaAposentadoria, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, valorNaAposentadoriaMensal, null,
                                            anoFinalVaiAposentar + 1 === anoInicial ? mesAnoInicial : 1, anoFinalVaiAposentar + 1, 12, anoFinal)
                                }
                                
                                /**/
                                calculaGraficoAcumulacaoMensal(null, anoFinalVaiAposentar + 1, null, anoFinal, 0,
                                    valorNaAposentadoriaMensal, null, estudo.current.graficoAnos,
                                        estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                            estudo.current.graficoDespesaAnualTotalMedia,
                                                values, estudo, 0, 0, false, null, null, null)
                                /**/
                            }
                        }

                        valorAcumulado = estudo.current.graficoDiaDiaDespesasVitalicias[0] - valorAcumulado

                    } else {

                        valorAcumulado = 0
                    }
                    
                } else {

                    valorNaAposentadoria = valor
                    valorNaAposentadoriaMensal = valorMensal

                    if (
                            linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente ||
                            linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarConjuge
                    ) {

                        valorAcumulado = estudo.current.graficoDiaDiaDespesasVitalicias[0]

                        calculaGraficoValorPresente(null, anoInicial, null, anoFinalClienteConjuge, valor/*valorMensal*/,
                            linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                estudo.current.graficoAnos, estudo.current.graficoDiaDiaDespesasVitalicias,
                                    estudo.current.graficoDespesasAcumuladas, values, estudo,
                                        values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                            values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                                values.taxaRetornoAnualRealAposAposentadoria, null, null, true
                        )
        
                        if (linhaTipoDiaDiaDespesasVitalicias.morte === "N" && linhaTipoDiaDiaDespesasVitalicias.invalidez === "N") {

                            if (linhaDiaDiaDespesasVitalicias.consumirRecursosFinanceirosEmVezDaReceitaMensal) {

                                saldo = calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                                    /*valorMensal*/valor * -1, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null,
                                                            estudo.current.evolucaoReservaFinanceiraEstudo)

                                if (anoFinal < values.anoFinalVitalicioClienteConjuge) {

                                    calculaGraficoAcumulacaoMensal(null, anoFinal + 1, null,
                                        values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null)
                                }

                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                                    valor/*valorMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/,
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                                null, values, estudo, 0, 0, false, null, null, null)
        
                            } else {

                                //calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                                //    valorMensal, null, estudo.current.graficoAnos,
                                //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null)

                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                                    valor/*valorMensal*/, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto/*null*/, 
                                        estudo.current.graficoAnos,
                                            estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                                estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                                    null, null, null)

                                incluiDespesaMensalDetalhadaTemp(values, estudo, linhaDiaDiaDespesasVitalicias, descricaoDiaDiaDespesasVitalicias,
                                    valor, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, valorMensal, null,
                                        mesAnoInicial, anoInicial, 12, anoFinal)

                                /**/
                                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                                    valorMensal, null, estudo.current.graficoAnos,
                                        estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                            estudo.current.graficoDespesaAnualTotalMedia,
                                                values, estudo, 0, 0, false, null, null, null)
                                /**/
                            }
                        }
                        
                        valorAcumulado = estudo.current.graficoDiaDiaDespesasVitalicias[0] - valorAcumulado

                    } else {
                        
                        valorAcumulado = 0
                    }
                }

                if (
                        (
                            !dadosCompletosConjuge(values) &&
                            !possuiFamiliares(values) &&
                            (
                                values.morteInvalidezEstudo === "A" ||
                                values.morteInvalidezEstudo === "M"
                            ) &&
                            linhaTipoDiaDiaDespesasVitalicias.informaDependente !== "S"
                        ) ||
                        (
                            linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarCliente &&
                            linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarConjuge
                        )
                ) {

                    valorResponsabilidadeCliente = 0
                    valorResponsabilidadeConjuge = 0

                } else {

                    if (
                            (
                                linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarCliente &&
                                linhaDiaDiaDespesasVitalicias.despesaPessoal &&
                                (
                                    values.morteInvalidezEstudo === "A" ||
                                    values.morteInvalidezEstudo === "M"
                                )
                            ) ||
                            (
                                linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarCliente &&
                                linhaTipoDiaDiaDespesasVitalicias.informaDependente === "S"
                            ) ||
                            (
                                linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarCliente &&
                                (
                                    linhaTipoDiaDiaDespesasVitalicias.morte === "S" ||
                                    linhaTipoDiaDiaDespesasVitalicias.invalidez === "S"
                                )
                            )
                    ) {

                        valorResponsabilidadeCliente = 0

                    } else {
                        
                        valorResponsabilidadeCliente = valorAcumulado
                    }

                    if (
                            (
                                linhaDiaDiaDespesasVitalicias.familiar === HARDCODE.idFamiliarConjuge &&
                                linhaDiaDiaDespesasVitalicias.despesaPessoal &&
                                (
                                    values.morteInvalidezEstudo === "A" ||
                                    values.morteInvalidezEstudo === "M"
                                )
                            ) ||
                            (
                                linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarConjuge &&
                                linhaTipoDiaDiaDespesasVitalicias.informaDependente === "S"
                            ) ||
                            (
                                linhaDiaDiaDespesasVitalicias.familiar !== HARDCODE.idFamiliarConjuge &&
                                (
                                    linhaTipoDiaDiaDespesasVitalicias.morte === "S" ||
                                    linhaTipoDiaDiaDespesasVitalicias.invalidez === "S"
                                )
                            )
                    ) {

                        valorResponsabilidadeConjuge = 0

                    } else {
                        
                        valorResponsabilidadeConjuge = valorAcumulado
                    }
                }

                diaDiaDespesasVitaliciasTemp.push({
                    ordem: ("0000" + linhaDiaDiaDespesasVitalicias.familiar).slice(-4) + 
                        ("000000000000000000000" + valorMensal).slice(-21),
                    familiar: linhaDiaDiaDespesasVitalicias.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricao: descricaoDiaDiaDespesasVitalicias,
                    despesaPessoal: linhaDiaDiaDespesasVitalicias.despesaPessoal,
                    descricaoGrupoDespesa:
                        calculaDescricaoGrupoDespesa(linhaDiaDiaDespesasVitalicias.grupoDespesa,
                            linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesaOutras),
                    valor: valor,/**/
                    tipoPeriodicidadePagto: linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto,/**/
                    valorMensal: valorMensal,
                    //mesesValorMensal: mesesValorMensal,
                    tempoValor: converteMesesTipoPeriodicidade(mesesValorMensal, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto),
                    valorNaAposentadoria: valorNaAposentadoria,/**/
                    valorNaAposentadoriaMensal: valorNaAposentadoriaMensal,
                    //mesesValorNaAposentadoriaMensal: mesesValorNaAposentadoriaMensal,
                    tempoValorNaAposentadoria:
                        converteMesesTipoPeriodicidade(mesesValorNaAposentadoriaMensal, linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto),
                    valorAcumulado: valorAcumulado,
                    valorResponsabilidadeCliente: valorResponsabilidadeCliente,
                    valorResponsabilidadeConjuge: valorResponsabilidadeConjuge,
                    id: linhaDiaDiaDespesasVitalicias.id,
                    valorAcumuladoDependente:
                        !linhaDiaDiaDespesasVitalicias.despesaPessoal && linhaTipoDiaDiaDespesasVitalicias.informaDependente === "S"
                            ?
                                valorAcumulado
                            :
                                '',
                    anoFinal: anoFinalClienteConjuge,
                })

                valorMensalTotal += valorMensal
                valorNaAposentadoriaMensalTotal += valorNaAposentadoriaMensal
                valorAcumuladoTotal += valorAcumulado
                valorResponsabilidadeClienteTotal += valorResponsabilidadeCliente
                valorResponsabilidadeConjugeTotal += valorResponsabilidadeConjuge
            }
        })

        diaDiaDespesasVitaliciasTemp = sortAsc(diaDiaDespesasVitaliciasTemp)

        let familiarAnt = null

        diaDiaDespesasVitaliciasTemp.forEach( (linhaDiaDiaDespesasVitalicias, index) => {
            if (linhaDiaDiaDespesasVitalicias.familiar !== familiarAnt) {
                familiarAnt = linhaDiaDiaDespesasVitalicias.familiar

                valorMensal = 0
                valorNaAposentadoriaMensal = 0
                valorAcumulado = 0
                valorResponsabilidadeCliente = 0
                valorResponsabilidadeConjuge = 0

                diaDiaDespesasVitaliciasTemp.forEach( (linhaDiaDiaDespesasVitaliciasTotal, index) => {
                    if (linhaDiaDiaDespesasVitaliciasTotal.familiar === linhaDiaDiaDespesasVitalicias.familiar) {
                        valorMensal += linhaDiaDiaDespesasVitaliciasTotal.valorMensal
                        valorNaAposentadoriaMensal += linhaDiaDiaDespesasVitaliciasTotal.valorNaAposentadoriaMensal
                        valorAcumulado += linhaDiaDiaDespesasVitaliciasTotal.valorAcumulado
                        valorResponsabilidadeCliente += 
                            linhaDiaDiaDespesasVitaliciasTotal.valorResponsabilidadeCliente
                        valorResponsabilidadeConjuge += 
                            linhaDiaDiaDespesasVitaliciasTotal.valorResponsabilidadeConjuge
                    }
                })

                diaDiaDespesasVitalicias.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaDiaDiaDespesasVitalicias.nomeCurtoFamiliar,
                            report),
                    valorMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                    valorNaAposentadoriaMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorNaAposentadoriaMensal, report),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorResponsabilidadeCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeCliente, report),
                    valorResponsabilidadeConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeConjuge, report),
                })
            }

            diaDiaDespesasVitalicias.push({
                familiar: linhaDiaDiaDespesasVitalicias.familiar,
                descricao: 
                    linhaDiaDiaDespesasVitalicias.despesaPessoal
                        ?
                            formataTableCellBodyExibeDadosTituloDestaque2Titulos("          " + 
                                linhaDiaDiaDespesasVitalicias.descricao, LABEL.pessoal, report)
                        :
                            formataTableCellBodyExibeDadosTitulo("          " + linhaDiaDiaDespesasVitalicias.descricao, report),
                descricaoGrupoDespesa: formataTableCellBodyExibeDadosTitulo(linhaDiaDiaDespesasVitalicias.descricaoGrupoDespesa, report),
                valorMensal: 
                    linhaDiaDiaDespesasVitalicias.valor === linhaDiaDiaDespesasVitalicias.valorMensal
                        ?
                            formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasVitalicias.valorMensal, report)
                        :
                            formataValorTipoPeriodicidade(linhaDiaDiaDespesasVitalicias.valor, 2, false, false,
                                linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, false, report),
                tempoValor:
                    formataValorTipoPeriodicidade(linhaDiaDiaDespesasVitalicias.tempoValor, 0, true, false,
                        linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, true, report),
                //mesesValorMensal:
                //    formataTableCellBodyExibeDadosTexto(linhaDiaDiaDespesasVitalicias.mesesValorMensal,
                //        report),
                valorNaAposentadoriaMensal: 
                    linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal === 
                        linhaDiaDiaDespesasVitalicias.valorMensal
                            ?
                                //formataTableCellBodyExibeDadosValor(
                                //    linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal, report)
                                linhaDiaDiaDespesasVitalicias.valorNaAposentadoria ===
                                linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal
                                    ?
                                        formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal,
                                            report)
                                    :
                                        formataValorTipoPeriodicidade(linhaDiaDiaDespesasVitalicias.valorNaAposentadoria, 2, false, false,
                                            linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, false, report)
                            :
                                //formataTableCellBodyExibeDadosValorDestaque(
                                //    linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal, report),
                                linhaDiaDiaDespesasVitalicias.valorNaAposentadoria === linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal
                                    ?
                                        formataTableCellBodyExibeDadosValorDestaque(
                                            linhaDiaDiaDespesasVitalicias.valorNaAposentadoriaMensal, report)
                                    :
                                        formataValorTipoPeriodicidade(linhaDiaDiaDespesasVitalicias.valorNaAposentadoria, 2, false, true,
                                            linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, false, report),
                tempoValorNaAposentadoria:
                    formataValorTipoPeriodicidade(linhaDiaDiaDespesasVitalicias.tempoValorNaAposentadoria, 0, true, false,
                        linhaDiaDiaDespesasVitalicias.tipoPeriodicidadePagto, true, report),
                //mesesValorNaAposentadoriaMensal:
                //    formataTableCellBodyExibeDadosTexto(
                //        linhaDiaDiaDespesasVitalicias.mesesValorNaAposentadoriaMensal, report),
                valorAcumulado: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasVitalicias.valorAcumulado, report),
                valorResponsabilidadeCliente: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasVitalicias.valorResponsabilidadeCliente, report),
                valorResponsabilidadeConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasVitalicias.valorResponsabilidadeConjuge, report),
                id: linhaDiaDiaDespesasVitalicias.id,
                valorAcumuladoDependente: linhaDiaDiaDespesasVitalicias.valorAcumuladoDependente,
                anoFinal: linhaDiaDiaDespesasVitalicias.anoFinal,
            })
        })

        diaDiaDespesasVitalicias.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorMensal:
                formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
            valorNaAposentadoriaMensal:
                formataTableCellBodyExibeDadosTotalValor(valorNaAposentadoriaMensalTotal, report),
            valorAcumulado:
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorResponsabilidadeCliente:
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeClienteTotal, report),
            valorResponsabilidadeConjuge:
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeConjugeTotal, report),
        })
    
        panelDespesasTotaisDiaDiaDespesasVitalicias = {
            descricao: LABEL.diaDia,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: valorResponsabilidadeClienteTotal,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: valorResponsabilidadeConjugeTotal,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: calculaIntervaloTempoAnosMorteAteVitalicio(values),
        }

    } else {

        panelDespesasTotaisDiaDiaDespesasVitalicias = {
            descricao: LABEL.diaDia,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.diaDiaDespesasVitalicias = diaDiaDespesasVitalicias

    estudo.current.panelDespesasTotais[HARDCODE.indexEstudoDiaDia] = panelDespesasTotaisDiaDiaDespesasVitalicias
}