import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import MESSAGE from '../../business-const/Message/Message'

import AuthService from '../../business-api/AuthService'

//import ClienteService from '../../business-api/ClienteService'

import {
    CLASSNAME
} from '../../business-const/Style/Style'

import {
    calcEstudoReportCapaTitulo1,
    calcProfissaoQualificacao,
} from '../../views/clientes/estudo-report/EstudoReportService'

import backgroundCapaRelatorioImg from './backgroundCapaRelatorio.png'



export const ImgLogoTopbar = ( props ) => {

    const { variaveisGlobais, cadastro, ...rest } = props

    const tamanhoLogo =
        AuthService.obterTamanhoLogoTopbar(variaveisGlobais) && !cadastro
            ?
                AuthService.obterTamanhoLogoTopbar(variaveisGlobais)
            :
                AuthService.obterTamanhoLogo(variaveisGlobais)
    const height = tamanhoLogo ? tamanhoLogo.alturaLogoTopbar : null
    const width = tamanhoLogo ? tamanhoLogo.larguraLogoTopbar : null

    return (
        <img
            height={height ? height : "60"}
            width={width ? width : "120"}
            alt={''}
            {...rest}
        />
    )
}

export const ImgLogoReport = ( props ) => {

    const tamanhoLogo = AuthService.obterTamanhoLogo(props.variaveisGlobais)
    const height = tamanhoLogo ? tamanhoLogo.alturaLogoReport : null
    const width = tamanhoLogo ? tamanhoLogo.larguraLogoReport : null

    return (
        <img
            height={height ? height : "60"}
            width={width ? width : "120"}
            alt={''}
            {...props}
        />
    )
}

export const ImgLogoLoginSidenav = ( props ) => {

    return (
        <img
            height="80"
            width="120"
            alt={''}
            {...props}
        />
    )
}


export const ImgLogoSigninSideNav = ( props ) => {

    return (
        <img
            className='w-full'
            alt={''}
            {...props}
        />
    )
}

export const ImgBackground = ( props ) => {

    return (
        <img
            height="60"
            width="120"
            alt={''}
            {...props}
        />
    )
}

export const ImgBackgroundRelatorio = ( props ) => {

    return (
        <img
            className='w-full'
            alt={''}
            {...props}
        />
    )
}



const useStyles = makeStyles(theme => ({
    //imgLogoSigninBackground: ({ variaveisGlobais }) => ({
    //    backgroundImage:  "url('" + AuthService.obterBackground(variaveisGlobais) + "')",
    //    backgroundRepeat: 'repeat',
    //}),
    imgCapaBackground: ({ variaveisGlobais, values }) => ({
        height: '1169px',
        width: '820px',
        position: 'relative',
        top: '0px',
        backgroundImage:  "url('" +
            (AuthService.obterBackgroundCapaRelatorio(variaveisGlobais)
                ?
                    AuthService.obterBackgroundCapaRelatorio(variaveisGlobais)
                :
                    values && values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato
                        ?
                            backgroundCapaRelatorioImg
                        :
                            ''
            ) + "')",
        backgroundRepeat: 'no-repeat',
    }),
}))

//export const ImgLogoSigninBackground = ( props ) => {

//    const { variaveisGlobais, values, ...rest} = props

//    const classes = useStyles({variaveisGlobais, values})

//    return (
//        <div
//            className={clsx('w-full h-full-screen', classes.imgLogoSigninBackground)}
//            alt={''}
//            {...rest}
//        />
//    )
//}

export const TextoBackground = ( props ) => {

    const virgula = props.jsonConfiguracao.estudoReportCapaNaoExibirTitulo2 ? '' : LABEL.virgula

    const numeroMargemTopoTitulo1 =
        (props.jsonConfiguracao &&
        props.jsonConfiguracao.estudoReportCapaTitulo1MargemTopo &&
        !props.jsonConfiguracao.estudoReportCapaNaoExibirTitulo1
            ?
                props.jsonConfiguracao.estudoReportCapaTitulo1MargemTopo
            :
                20
        )

    const margemTopoTitulo1 = 'mt-' + (numeroMargemTopoTitulo1 + (props.jsonConfiguracao.backgroundCapaRelatorioPaginaInteira ? 26 : 0))

    const margemTopoClienteConjugeTitulo2 = 'mt-' + 
        (props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo
            ?
                props.jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo - numeroMargemTopoTitulo1
            :
                (props.values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? 138 : 78) - numeroMargemTopoTitulo1
        )

    const margemEsquerdaClienteConjugeTitulo2 = 'ml-' + 
        (props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda
            ?
                props.jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda
            :
                '5'
        )

    const corTexto = 
        (props.jsonConfiguracao &&
        props.jsonConfiguracao.estudoReportCapaExibirTextoCorBranca &&
        props.values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato // temporário
            ?
                'text-white'
            :
                ''
        )

    return (
        <>
            <br/>

            <div
                align="center"
                className={clsx(margemTopoTitulo1, corTexto)}
            >
                <div
                    className='text-30 font-light-medium' // mudança da fonte: text-36
                >
                    {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaNaoExibirTitulo1
                        ?
                            <br/>
                        :
                            calcEstudoReportCapaTitulo1(props.jsonConfiguracao)
                    }
                </div>

                <br/>
                <br/>

                <div
                    className='text-20 font-light'
                >
                    {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaNaoExibirUsuario
                        ?
                            <br/>
                        :
                            //ClienteService.obterUsuarioClienteSelecionadoIdentificacao(props.variaveisGlobais)
                            AuthService.obterIdentificacaoUsuarioLogado(props.variaveisGlobais)
                    }
                </div>

                <br/>

                <div
                    className='text-14 mt-2'
                >
                    {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaNaoExibirProfissaoQualificacao
                        ?
                            <br/>
                        :
                            calcProfissaoQualificacao(props.jsonUsuario)
                    }
                </div>
            </div>

            <div
                className={margemTopoClienteConjugeTitulo2}
                align='left'
            >
                {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaNaoExibirClienteConjuge
                    ?
                        ''
                    :
                        <>
                            <h3>
                                {props.nomeCurtoConjuge && props.nomeCurtoCliente.length + props.nomeCurtoConjuge.length >
                                HARDCODE.tamanhoMaximoReportCapaNomeCurtoClienteEConjuge
                                    ?
                                        ''
                                    :
                                        <>
                                            <br/>
                                            <br/>
                                        </>
                                }
                            </h3>

                            <span
                                className={clsx(margemEsquerdaClienteConjugeTitulo2, corTexto, CLASSNAME().formataReportCapaNomeCurto)}
                            >
                                {props.nomeCurtoCliente}

                                {props.nomeCurtoConjuge
                                    ?
                                        props.nomeCurtoCliente.length + props.nomeCurtoConjuge.length >
                                        HARDCODE.tamanhoMaximoReportCapaNomeCurtoClienteEConjuge
                                            ?
                                                LABEL.e
                                            :
                                                LABEL.e + props.nomeCurtoConjuge + virgula
                                    :
                                        virgula
                                }
                            </span>

                            {props.nomeCurtoConjuge && props.nomeCurtoCliente.length + props.nomeCurtoConjuge.length >
                            HARDCODE.tamanhoMaximoReportCapaNomeCurtoClienteEConjuge
                                    ?
                                        <>
                                            <br/>

                                            <span
                                                className={clsx(margemEsquerdaClienteConjugeTitulo2, corTexto, CLASSNAME().formataReportCapaNomeCurto)}
                                            >
                                                {props.nomeCurtoConjuge + virgula}
                                            </span>
                                        </>
                                    :
                                        ''
                            }

                            <br/>
                        </>
                }

                {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaNaoExibirTitulo2
                    ?
                        ''
                    :
                        <span
                            className={clsx(margemEsquerdaClienteConjugeTitulo2, corTexto, CLASSNAME().formataReportCapaTitulo2)}
                        >
                            {props.jsonConfiguracao && props.jsonConfiguracao.estudoReportCapaTitulo2
                                ?
                                    props.jsonConfiguracao.estudoReportCapaTitulo2
                                :
                                    MESSAGE().estudoReportCapaTitulo2
                            }
                        </span>
                }
            </div>
        </>
    )
}
export const ImgCapaBackground = ( props ) => {

    const variaveisGlobais = props.variaveisGlobais
    const values = props.values

    const classes = useStyles({variaveisGlobais, values})
    


    return (
        <div
            align="left"
            className={
                clsx(
                    classes.imgCapaBackground,
                        props.jsonConfiguracao.backgroundCapaRelatorioPaginaInteira
                            ?
                                'mt--26'
                            :
                                '',
                            props.values.formatoReport === HARDCODE.idFormatoReportImpressao ? '' : ' ml--3'
                )}
            alt={''}
        >
            <TextoBackground
                {...props}
            />
        </div>
    )
}