// alterado - revisado

import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import MESSAGE from '../../../business-const/Message/Message'

import {
    mesMesAno,
    anoMesAno,
    formataDataEmMesAnoSemBarra,
} from '../../../business-components/Date/Date'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoDiaDiaList from '../../../business-rules/List/tipoDiaDiaList'

import tipoFormaPagtoList from '../../../business-rules/List/tipoFormaPagtoList'

import {
    calculaValorMensal,
    calculaValorAcumulado,
} from '../cliente-form/ClienteService'

import {
    atualizaPerguntaValorOuValor2,
} from '../form-form/FormServicePerguntas'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'



export const incrementaContDiaDia = ( values ) => {

    if (!values.contDiaDia) {

        values.contDiaDia = 0
    }

    values.contDiaDia = values.contDiaDia + 1
}



export const processaRegistrosDefaultIniciaisDiaDia = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisDiaDia) {

            values.familiaresProcessouRegistrosDefaultIniciaisDiaDia = []
        }

        if (!values.diaDia) {

            values.diaDia = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisDiaDia, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisDiaDia.push(
                {
                    id: familiar
                }
            )

            incrementaContDiaDia(values)
            values.diaDia.push(
                {
                    id: values.contDiaDia,
                    familiar: familiar,
                }
            )
        }
    }
}

export const atualizaFormListDiaDia = ( values, idPergunta, valor, idDiaDia, familiar ) => {

    if (valor > 0) {

        if (!idDiaDia) {
            
            if (!values.diaDia) {

                values.diaDia = []
            }

            incrementaContDiaDia(values)
            values.diaDia.push(
                {
                    id: values.contDiaDia,
                    familiar: familiar,
                    tipoDiaDia: HARDCODE.idTipoDiaDiaOutros,
                    valor: valor,
                    tipoPeriodicidadePagto: HARDCODE.idTipoPeriodicidadeMensal,
                    observacao: MESSAGE().incluidoPerguntas,
                    idPergunta: idPergunta,
                }
            )

            idDiaDia = values.contDiaDia

        } else {

            values.diaDia?.forEach( (linhaDiaDia, index) => {

                if (linhaDiaDia.idPergunta === idPergunta && linhaDiaDia.familiar === familiar) {
    
                    values.diaDia[index].valor = valor
                }
            })
        }

        calculaTotaisDiaDia(values, {})

    } else {

        if (idDiaDia) {

            let index = values.diaDia.length - 1

            while (index >= 0) {

                if (
                        values.diaDia[index].idPergunta === idPergunta &&
                        values.diaDia[index].familiar === familiar
                ) {

                    values.diaDia.splice(index, 1)
                }

                index -= 1
            }

            idDiaDia = null

            calculaTotaisDiaDia(values, {})
        }
    }

    return idDiaDia
}



const calculaDiaDiaTipoPeriodicidadePagto = ( item ) => {

    if (!item.tipoPeriodicidadePagto && item.tipoDiaDia) {

        item.tipoPeriodicidadePagto = pesquisaList(tipoDiaDiaList, item.tipoDiaDia).tipoPeriodicidadePagtoDefault
    }
}

const calculaDiaDiaZeraCamposNaoInformados = ( item ) => {

    const linhaTipoDiaDiaList = pesquisaList(tipoDiaDiaList, item.tipoDiaDia)

    if (linhaTipoDiaDiaList.informaDependente !== "S") {

        item.parentescoDependente = ''
    }

    if (
            item.alterarPeriodoDiaDia ||
            (
                item.familiar !== HARDCODE.idFamiliarCliente &&
                item.familiar !== HARDCODE.idFamiliarConjuge
            )
    ) {

        item.valorNaAposentadoria = ''
    }

    if (!item.alterarPeriodoDiaDia) {

        item.mesAnoInicial = null
        item.mesAnoFinal = null
        item.diaDiaIraOcorrerAteExpectativaVida = ''
    }

    if (item.diaDiaIraOcorrerAteExpectativaVida) {

        item.mesAnoFinal = null
    }

    if (!item.alterarPeriodoDiaDia || linhaTipoDiaDiaList.informaValorCompra !== "S") {

        item.valorCompra = ''
    }

    if (linhaTipoDiaDiaList.morte === "S" || linhaTipoDiaDiaList.invalidez === "S") {

        item.despesaPessoal = ''
        item.consumirRecursosFinanceirosEmVezDaReceitaMensal = ''
        item.grupoDespesa = ''
    }

    calculaDiaDiaTitularRespValorCompra(item) 
}

const calculaDiaDiaGrupoDespesa = ( item ) => {

    if (!item.grupoDespesa && item.tipoDiaDia) {

        item.grupoDespesa = pesquisaList(tipoDiaDiaList, item.tipoDiaDia).grupoDespesa
    }
}

export const processaAlteracaoDiaDiaTipoDiaDia = ( item ) => {

    calculaDiaDiaTipoPeriodicidadePagto(item)

    calculaDiaDiaZeraCamposNaoInformados(item)
    
    calculaDiaDiaGrupoDespesa(item)
}



const calculaValorAcumuladoDiaDiaItem = ( values, item ) => {

    if (
            item.valor &&
            item.tipoPeriodicidadePagto &&
            item.mesAnoInicial && 
            item.mesAnoFinal &&
            pesquisaList(tipoDiaDiaList, item.tipoDiaDia).informaValorCompra === "S"
    ) {

        item.valorAcumulado =
            calculaValorAcumulado (values, item.valor, item.tipoPeriodicidadePagto,
                mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal),
            )

    } else {

        item.valorAcumulado = ''
    }
}

export const processaAlteracaoDiaDiaTipoPeriodicidadePagto = ( values, item ) => {

    calculaValorAcumuladoDiaDiaItem(values, item)
}

export const processaAlteracaoDiaDiaValor = ( values, item ) => {

    calculaValorAcumuladoDiaDiaItem(values, item)
    
    atualizaPerguntaValorOuValor2(values, item.idPergunta, item.valor, item.familiar)
}



export const processaAlteracaoDiaDiaAlterarPeriodoDiaDia = ( item, values ) => {

    calculaDiaDiaZeraCamposNaoInformados(item)

    if (item.alterarPeriodoDiaDia) {

        if (!item.mesAnoInicial && !item.mesAnoFinal) {

            item.mesAnoInicial = formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
            item.mesAnoFinal = '12' + values.anoFinalClienteConjuge
        }
    }

    calculaValorAcumuladoDiaDiaItem(values, item)
}

export const processaAlteracaoDiaDiaMesAnoInicial = ( values, item ) => {
    
    calculaValorAcumuladoDiaDiaItem(values, item)
}

export const processaAlteracaoDiaDiaMesAnoFinal = ( values, item ) => {

    calculaValorAcumuladoDiaDiaItem(values, item)
}


const calculaDiaDiaTitularRespValorCompra = ( item ) => {

    if (!item.valorCompra) {

        item.titularRespValorCompra = ''
    }
}

export const processaAlteracaoDiaDiaValorCompra = ( item ) => {

    calculaDiaDiaTitularRespValorCompra(item)
}



export const calculaDescricaoGrupoDespesaOutras = ( item ) => {

    if (item.grupoDespesa !== HARDCODE.idGrupoDespesaOutras) {

        item.descricaoGrupoDespesaOutras = ''
    }
}

export const processaAlteracaoDescricaoGrupoDespesaOutras = ( item ) => {

    calculaDescricaoGrupoDespesaOutras(item)
}



export const dadosCompletosLinhaDiaDia = ( linhaDiaDia ) => {

    const linhaTipoDiaDiaList = pesquisaList(tipoDiaDiaList, linhaDiaDia.tipoDiaDia)

    return  linhaDiaDia.valor &&
            (linhaTipoDiaDiaList.informaDependente !== "S" || linhaDiaDia.parentescoDependente) &&
            linhaDiaDia.tipoPeriodicidadePagto &&
            (
                !linhaDiaDia.alterarPeriodoDiaDia ||
                (
                    linhaDiaDia.alterarPeriodoDiaDia &&
                    linhaDiaDia.mesAnoInicial &&
                    (linhaDiaDia.mesAnoFinal || linhaDiaDia.diaDiaIraOcorrerAteExpectativaVida)
                ) ||
                (
                    linhaDiaDia.alterarPeriodoDiaDia &&
                    !linhaDiaDia.mesAnoInicial &&
                    !linhaDiaDia.mesAnoFinal
                )
            )
}

export const calculaTotaisDiaDia = ( values, jsonConfiguracao ) => {
    
    const indexStepsValorFluxoCaixa = HARDCODE.indexFluxoCaixaDiaDia

    const labelFormaPagtoCartaoCredito =
        pesquisaDescricaoList(tipoFormaPagtoList(jsonConfiguracao),
            HARDCODE.idTipoFormaPagtoCartaoCredito)

    const labelFormaPagtoDebitoDinheiro =
        pesquisaDescricaoList(tipoFormaPagtoList(jsonConfiguracao),
            HARDCODE.idTipoFormaPagtoDebitoDinheiro)

    let linhaTipoDiaDiaList

    values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 0
    values.stepsValor2FluxoCaixa[indexStepsValorFluxoCaixa] = 0
    values.stepsValorSubTotal1FluxoCaixa[indexStepsValorFluxoCaixa].title = labelFormaPagtoCartaoCredito
    values.stepsValorSubTotal1FluxoCaixa[indexStepsValorFluxoCaixa].valor = 0
    values.stepsValorSubTotal2FluxoCaixa[indexStepsValorFluxoCaixa].title = labelFormaPagtoDebitoDinheiro
    values.stepsValorSubTotal2FluxoCaixa[indexStepsValorFluxoCaixa].valor = 0

    values.totalDiaDia = []

    let valorMensalMedio
    let valorNaAposentadoriaMensalMedio

    let valorMensalMedioTotal
    let valorNaAposentadoriaMensalMedioTotal
    let valorMensalMedioTotalCartaoCredito
    let valorMensalMedioTotalDebitoDinheiro

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        valorMensalMedioTotal = 0
        valorNaAposentadoriaMensalMedioTotal = 0
        valorMensalMedioTotalCartaoCredito = 0
        valorMensalMedioTotalDebitoDinheiro = 0

        values.diaDia?.forEach( (linhaDiaDia, index) => {

            linhaTipoDiaDiaList = pesquisaList(tipoDiaDiaList, linhaDiaDia.tipoDiaDia)
            
            if (
                    linhaDiaDia.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaDiaDia(linhaDiaDia) &&
                    (
                        !linhaDiaDia.alterarPeriodoDiaDia ||
                        (
                            linhaDiaDia.alterarPeriodoDiaDia &&
                            linhaDiaDia.mesAnoInicial === formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
                        )
                    ) &&
                    linhaTipoDiaDiaList.morte === "N" &&
                    linhaTipoDiaDiaList.invalidez === "N"
            ) {

                valorMensalMedio = calculaValorMensal(linhaDiaDia.valor, linhaDiaDia.tipoPeriodicidadePagto)

                if (linhaDiaDia.valorNaAposentadoria) {

                    valorNaAposentadoriaMensalMedio =
                        calculaValorMensal(linhaDiaDia.valorNaAposentadoria, linhaDiaDia.tipoPeriodicidadePagto)
                        
                } else {

                    if  (
                            linhaDiaDia.alterarPeriodoDiaDia &&
                            anoMesAno(linhaDiaDia.mesAnoFinal) <= values.anoFinalVaiAposentarCliente
                    ) {

                        valorNaAposentadoriaMensalMedio = 0

                    } else {

                        valorNaAposentadoriaMensalMedio = valorMensalMedio
                    }
                }

                valorMensalMedioTotal += valorMensalMedio
                valorNaAposentadoriaMensalMedioTotal += valorNaAposentadoriaMensalMedio

                if (linhaDiaDia.tipoFormaPagto === HARDCODE.idTipoFormaPagtoCartaoCredito) {
                    valorMensalMedioTotalCartaoCredito += valorMensalMedio
                }
                
                if (linhaDiaDia.tipoFormaPagto === HARDCODE.idTipoFormaPagtoDebitoDinheiro) {
                    valorMensalMedioTotalDebitoDinheiro += valorMensalMedio
                }
            }
        })

        if (
                valorMensalMedioTotal !== 0 ||
                valorNaAposentadoriaMensalMedioTotal !== 0 ||
                valorMensalMedioTotalCartaoCredito !== 0 ||
                valorMensalMedioTotalDebitoDinheiro !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {

            values.totalDiaDia.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    valor: valorMensalMedioTotal,
                    title2: LABEL.valorNaAposentadoria,
                    valor2: 
                        valorNaAposentadoriaMensalMedioTotal > 0 ||
                        linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                        linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
                            ? 
                                valorNaAposentadoriaMensalMedioTotal
                            :
                                '',
                    subTotal1Title: labelFormaPagtoCartaoCredito,
                    subTotal1Valor: valorMensalMedioTotalCartaoCredito,
                    subTotal2Title: labelFormaPagtoDebitoDinheiro,
                    subTotal2Valor: valorMensalMedioTotalDebitoDinheiro,
                }
            )

            values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] += valorMensalMedioTotal
            values.stepsValor2FluxoCaixa[indexStepsValorFluxoCaixa] += valorNaAposentadoriaMensalMedioTotal
            values.stepsValorSubTotal1FluxoCaixa[indexStepsValorFluxoCaixa].valor += valorMensalMedioTotalCartaoCredito
            values.stepsValorSubTotal2FluxoCaixa[indexStepsValorFluxoCaixa].valor += valorMensalMedioTotalDebitoDinheiro
        }
    })
}



export const dadosCompletosDiaDia = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.diaDia?.forEach( (linhaDiaDia, index) => {

            if (
                    linhaDiaDia.familiar === linhaFamiliaresList.id &&
                    linhaDiaDia.tipoDiaDia &&
                    !dadosCompletosLinhaDiaDia(linhaDiaDia)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos, pesquisaDescricaoList(tipoDiaDiaList, linhaDiaDia.tipoDiaDia))
            }
        })
    })

    return dadosIncompletos
}



export const calculaDiaDiaSemDespesasTemporarias = ( values ) => {

    let linhaTipoDiaDiaList

    let valorMensalMedio

    let diaDiaSemDespesasTemporarias = 0

    values.diaDia?.forEach( (linhaDiaDia, index) => {
        
        linhaTipoDiaDiaList = pesquisaList(tipoDiaDiaList, linhaDiaDia.tipoDiaDia)

        if (
                dadosCompletosLinhaDiaDia(linhaDiaDia) &&
                !linhaDiaDia.alterarPeriodoDiaDia &&
                !pesquisaList(values.exclusaoFamiliares, linhaDiaDia.familiar).id &&
                !pesquisaList(values.exclusaoDespesasGarantias,
                    LABEL.diaDia + '/' + linhaDiaDia.id).id &&
                linhaTipoDiaDiaList.morte === "N" &&
                linhaTipoDiaDiaList.invalidez === "N"
        ) {

            valorMensalMedio = calculaValorMensal(linhaDiaDia.valor, linhaDiaDia.tipoPeriodicidadePagto)

            diaDiaSemDespesasTemporarias += valorMensalMedio
        }
    })

    return diaDiaSemDespesasTemporarias
}